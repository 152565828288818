// Label functions
window.Label = window.Label || {};

Label.cacheSelectors = function () {
  Label.cache = {
    // General
    $html                    : $('html'),
    $body                    : $('body'),

    // Navigation
    $navigation              : $('#AccessibleNav'),
    $subNav                  : $('.sub-nav'),
    $mobileSubNavToggle      : $('.mobile-nav__toggle'),

    // Collection Pages
    $changeView              : $('.change-view'),

    // Product Page
    $productImage            : $('#ProductPhotoImg'),
    $thumbImages             : $('#ProductThumbs').find('a.product-single__thumbnail'),

    // Customer Pages
    $recoverPasswordLink     : $('#RecoverPassword'),
    $hideRecoverPasswordLink : $('#HideRecoverPasswordLink'),
    $recoverPasswordForm     : $('#RecoverPasswordForm'),
    $customerLoginForm       : $('#CustomerLoginForm'),
    $passwordResetSuccess    : $('#ResetSuccess')
  };
};

Label.init = function () {
  FastClick.attach(document.body);
  Label.cacheSelectors();
  Label.subNav();
  Label.drawersInit();
  Label.mobileNavToggle();
  Label.productImageSwitch();
  Label.responsiveVideos();
  Label.collectionViews();
  Label.loginForms();
  Label.globals();
  Label.header();
  Label.heroLetterSpacing();
  Label.heroSlider();
  Label.checkNavSize();
  Label.resize();
  if( $('body').hasClass('template-index') ) {
  Label.homepage();
  }
  Label.sections();
  if( $('.section-product').length > 0 ) {
    Label.initProducts();
  }
  if( $('.collection-template').length > 0) {
    Label.collectionSorting();
  }
};

Label.sections = function() {
  $(document).on('shopify:section:load', function(e) {
      var container = e.target;
      if( $(container).hasClass('section-tour')) {
        Label.tourInit();
      }
      if( $(container).hasClass('section-video')) {
        Label.responsiveVideos();
      }
      if( $(container).hasClass('section-instagram')) {
        Label.instagramInit();
      }
      if( $(container).hasClass('section-site-header')) {
        Label.cacheSelectors();
        Label.header();
        Label.subNav();
        Label.heroLetterSpacing();
        Label.LeftDrawer.close();
        Label.LeftDrawer = '';
        Label.RightDrawer = '';
        Label.drawersInit();
      }
      if( $(container).hasClass('sidebar-menu')) {
        Label.LeftDrawer.close();
        Label.LeftDrawer = '';
        Label.RightDrawer = '';
        Label.drawersInit();
        Label.LeftDrawer.open();
        Label.cacheSelectors();
        Label.mobileNavToggle();
      }
      if( $(container).hasClass('section-single-product')) {
        Label.initProducts();
        Label.cacheSelectors();
        Label.productImageSwitch();
      }
      if( $(container).hasClass('collection-template')) {
        Label.cacheSelectors();
        Label.collectionSorting();
      }

  });
  $(document).on('shopify:section:select', function(e) {
    var id = e.target.id;
    switch(id) {
      case "shopify-section-sidebar-menu":
        if (!$('body').hasClass("js-drawer-open-left")) {
          $('.js-drawer-open-left').trigger("click");
        }
        break;
      case "shopify-section-header":
        Label.LeftDrawer.close();
        Label.LeftDrawer = '';
        Label.RightDrawer = '';
        Label.drawersInit();
        Label.header();
        Label.heroLetterSpacing();
        break;
      case "section-single-product":
        Label.initProducts();
        break;
      default:
    }
  });
  $(document).on('shopify:section:deselect', function(e) {
    var id = e.target.id;
    switch(id) {
      case "shopify-section-sidebar-menu":
        $('.js-drawer-close').trigger("click");
        break;
      default:
    }
  });
  $(document).on('shopify:block:select', function(e) {
    var id = e.target.id;
    var item = e.target;
    var scroll = ($(e.target).offset().top - 20);
    $('html, body').animate({
        scrollTop: scroll
    }, 200);
  });
};


Label.globals = function() {
  //nav width
  Label.globals.navWidth = 0;
  $('header .site-nav li').each(function() {
    Label.globals.navWidth = Label.globals.navWidth + $(this).outerWidth();
  });
  Label.isIE8 = false;
  if($('html').hasClass('lt-ie9')) {
    Label.isIE8 = true;
  }
};

Label.header = function() {
  $('.header_hero-image .arrow').click(function() {
    $('html, body').animate({
      scrollTop: $(".main-content").offset().top
  }, 500);
  });
  if(Label.isIE8) {
    var imgSrc = $('img.ie8-hero-image').data('src');
    $('img.ie8-hero-image').attr('src', imgSrc);
  }
};

Label.heroLetterSpacing = function() {
  if( $('.header_hero-image').length ) {
    $heroTitle = $('header.hero-active .logo-hero-image .site-header__logo a');
    if( $heroTitle.hasClass('letterspaced')) {
      return;
    }
    $heroTitleText = $heroTitle.text();
    $letterSpacing = $('header.hero-active .logo-hero-image .site-header__logo a').css('letter-spacing');
    if($letterSpacing) {
      $letterSpacing = parseInt( $letterSpacing.replace("px",""));
    }
    if($letterSpacing > 6) {
      var $words = $heroTitleText.split(" ");
      var $newString = "";
      for (var i = 0; i < $words.length; i++) {
          var $lastChar = $words[i][$words[i].length -1];
          $newString = $newString + $words[i].slice(0,-1) +  "<span>" + $lastChar + "</span> ";
      }
      $heroTitle.html($newString);
      $heroTitle.parents('.text-active').addClass("show");
      $heroTitle.addClass('letterspaced');
    } else {
      $heroTitle.parents('.text-active').addClass("show");
    }
  }
};

Label.heroSlider = function() {
  if( $('.header_hero-image').length ) {
    var $heroSlider = $('header.hero-active .hero_slider');
    var $heroSlides = $heroSlider.children('.hero_slide');
    function changeHeroSlide(idx) {
      $heroSlides.eq(idx).addClass('active').siblings().removeClass('active');
      window.setTimeout(function() {
        var next = idx + 1;
      	changeHeroSlide(next < $heroSlides.length ? next : 0);
      }, 8000);
    }
    changeHeroSlide(0);
  }
};

Label.homepage = function() {
  Label.instagramInit();
  Label.tourInit();
};

Label.instagramInit = function() {
  if($('.section-instagram').length > 0){
    //Label.instagram();
    $('.section-instagram').each(function() {
      if( $(this).find('.instagram').hasClass('instagram--onboarding')) {
        return;
      }
      $accessToken = $(this).find('section.instagram').data('accesstoken');
      Label.instagram($accessToken, $(this));
    });
  }
};

Label.tourInit = function() {
  if($('.section-tour').length > 0){
    $('.section-tour').each(function() {
      var max;
      switch($(this).find('.tour-events').data('source')) {
        case 'tour_songkick':
          var artist_id = $(this).find('.tour-events').data('songkick');
          max = $(this).find('.tour-events').data('maxevents');
          Label.songkick(artist_id, max, $(this));
          break;
        case 'tour_bandsintown':
          var artist_name = $(this).find('.tour-events').data('bandsintown');
          max = $(this).find('.tour-events').data('maxevents');
          Label.bandsintown(artist_name, max, $(this));
          break;
        case 'tour_eventbrite':
          var access_token = $(this).find('.tour-events').data('eventbrite');
          max = $(this).find('.tour-events').data('maxevents');
          Label.eventbrite(access_token, max, $(this));
          break;
      }
    });
  }
};

Label.checkNavSize  = function() {
  var totalWidth;
  if( $('header').hasClass('logo-aligned-left')){
    totalWidth = $('header .logo-and-nav').outerWidth();
    var logoWidth = $('header .logo-and-nav .site-header__logo a').outerWidth();
    var navWidth = $('header .site-nav').outerWidth();
    var breakpoint = (totalWidth - logoWidth) - 100;
    if(navWidth > breakpoint) {
      $('header .logo-and-nav, header .header-meta').addClass("break");
    }
    if(navWidth < breakpoint)  {
      $('header .logo-and-nav, header .header-meta').removeClass("break");
    }
  }
  if( $('header').hasClass('nav-aligned-left') ){
    totalWidth = $('header .nav-left-only').outerWidth();
    if(Label.globals.navWidth > (totalWidth - 80) ) {
      $('header .site-nav').addClass("hidden");
      $('header .site-header__burger-menu').addClass("show");
    } else {
      $('header .site-nav').removeClass("hidden");
      $('header .site-header__burger-menu').removeClass("show");
    }
  }
};

Label.resize = function() {
  $(window).bind('resize', function () {
      Label.checkNavSize();
  });
};

Label.instagram = function(accesstoken, item) {
  var _accesstoken = accesstoken;
  var _item = item;
  var _container = $(_item).find('.wrapper.grams .grid--full');
  var count = 8;
  var url = "//api.instagram.com/v1/users/self/media/recent?access_token=" + _accesstoken + "&count=" + count;
  $.ajax({
    url: url,
    type: "get",
    dataType: "jsonp",
    success: function(data) {
      for(var i=0; i<count; i++) {
        var imgURL = data.data[i].images.low_resolution.url;
        var imgW = data.data[i].images.low_resolution.width;
        var imgH = data.data[i].images.low_resolution.height;
        var permalink = data.data[i].link;
        var gramItem = $(_container).find('.gram-item-blank');
        var caption = '';
        if(data.data[i].caption){
          caption = data.data[i].caption.text;
        }
        var additionalClasses = "";
        if(i > 5) {
          additionalClasses = "xlarge--hide";
        }
        gramItem = gramItem.clone();
        gramItem.appendTo(_container);
        gramItem.removeClass('gram-item-blank').addClass('gram-item ' + additionalClasses);
        gramItem.find('a').attr('href', permalink);
        gramItem.find('a').append('<img src="'+imgURL+'" height="'+imgH+'" weight="'+imgW+'" alt="'+caption+'" />');
      }
    }
  });

};

Label.songkick = function(artist_id, max, item) {
  var _artistid = artist_id;
  var _max = max;
  var _item = $(item).find('.tour-events');
  var _sectionContent = $(item).find('.tour-events .wrapper.tour-item-wrapper .section-content');
	$.getJSON("//api.songkick.com/api/3.0/artists/" + _artistid + "/calendar.json?apikey=aeff44DVdi0z3VT6&jsoncallback=?",
	function(data){
		if(data.resultsPage.totalEntries === 0) {
      $(_item).find('.tour-item-no-events').addClass('show');
      $(_item).find('.button-wrapper a.btn').remove();
      return false;
		}
		var events = data.resultsPage.results.event;
		$(events).each(function (i) {
			if(i == _max){ return false; }
	    var dateDay = Date.parse( events[i].start.date).toString("dd");
	    var shortMonth = Date.parse( events[i].start.date).toString("MMM");
	    var venue = events[i].venue.displayName;
	    var location = events[i].location.city;
	    var link = events[i].uri;
	    var tourItem = $(_item).find('.tour-item-blank');
	    if(events[i].type == "Festival") {
		    venue = events[i].displayName;
	    }
	    tourItem = tourItem.clone();
	    tourItem.removeClass('tour-item-blank').addClass('tour-item');
	    tourItem.appendTo(_sectionContent);
	    tourItem.find('.date-day').html(dateDay);
	    tourItem.find('.date-month').html(shortMonth);
	    tourItem.find('.event-location').html(location);
	    tourItem.find('.event-venue').html(venue);
	    tourItem.find('a.ticket-link').attr('href', link);
		});
		$(_item).find('.button-wrapper').addClass('show');
    $(_item).find('.button-wrapper a.btn').attr('href','http://www.songkick.com/artists/' + _artistid);
	});
};

Label.bandsintown = function(artist_name, max, item) {
  var _artistName = artist_name;
  var _max = max;
  var _item = $(item).find('.tour-events');
  var _sectionContent = $(item).find('.tour-events .wrapper.tour-item-wrapper .section-content');
  var bandsintownName = _artistName.toLowerCase();
  bandsintownName = bandsintownName.replace(' ','%20');
  var bandsintownSlug = bandsintownName.replace('%20','');
  var url = "//api.bandsintown.com/artists/" + bandsintownName + "/events.json?api_version=2.0&app_id=giraffic_themes";
  $.ajax({
    url: url,
    type: "get",
    dataType: "jsonp",
    success: function(data) {
      var isEmpty = jQuery.isEmptyObject(data);
      if(isEmpty) {
        $(_item).find('.tour-item-no-events').addClass('show');
        $(_item).find('.button-wrapper a.btn').remove();
        return false;
      }
      $(data).each(function (i) {
        if(i == _max){ return false; }
        var dateDay = Date.parse( data[i].datetime).toString("dd");
		    var shortMonth = Date.parse( data[i].datetime).toString("MMM");
		    var venue = data[i].venue.name;
		    var location = data[i].formatted_location;
		    var link = data[i].ticket_url;
		    var tourItem = $('.tour-item-blank');
		    tourItem = tourItem.clone();
		    tourItem.removeClass('tour-item-blank').addClass('tour-item');
		    tourItem.appendTo(_sectionContent);
		    tourItem.find('.date-day').html(dateDay);
		    tourItem.find('.date-month').html(shortMonth);
		    tourItem.find('.event-location').html(location);
		    tourItem.find('.event-venue').html(venue);
		    tourItem.find('a.ticket-link').attr('href', link);
	    });
	    $(_item).find('.button-wrapper').addClass('show');
	    $(_item).find('.button-wrapper a.btn').attr('href','http://www.bandsintown.com/' + bandsintownSlug);
    }
  });
};

Label.eventbrite = function(access_token, max, item) {
  var _accessToken = access_token;
  var _max = max;
  var _item = $(item).find('.tour-events');
  var _sectionContent = $(item).find('.tour-events .wrapper.tour-item-wrapper .section-content');
  var url = "https://www.eventbriteapi.com/v3/users/me/owned_events/?token=" + _accessToken + "&expand=venue&status=live";
  $.ajax({
    url: url,
    type: "get",
    dataType: "json",
    success: function(data) {
      var events = data.events;
      $(events).each(function (i) {
        if(i == _max){ return false; }
        var dateDay = Date.parse( events[i].start.local).toString("dd");
		    var shortMonth = Date.parse( events[i].start.local).toString("MMM");
		    var name = events[i].name.text;
		    var venue = "";
		    if (events[i].venue) {
		      venue = events[i].venue.name + " &#183; " + events[i].venue.address.city + ", " + events[i].venue.address.country;
		    }
		    var link = events[i].url;
		    var tourItem = $('.tour-item-blank');
		    tourItem = tourItem.clone();
		    tourItem.removeClass('tour-item-blank').addClass('tour-item');
		    tourItem.appendTo(_sectionContent);
		    tourItem.find('.date-day').html(dateDay);
		    tourItem.find('.date-month').html(shortMonth);
		    tourItem.find('.event-location').html(name);
		    tourItem.find('.event-venue').html(venue);
		    tourItem.find('a.ticket-link').attr('href', link);
      });
    }
  });
};

Label.subNav = function () {
  var $nav = Label.cache.$navigation,
      $subNav = Label.cache.$subNav,
      $allLinks = $nav.find('a'),
      $topLevel = $nav.children('li').find('a'),
      $parents = $('header'),
      $subMenuLinks = $nav.find('.site-nav__dropdown').find('a');
      activeClass = 'nav-hover';
      focusClass = 'nav-focus';

  // Mouseenter
  $parents.on('click', ".site-nav--has-dropdown", function(evt) {
    var $el = $(this);
    evt.preventDefault();
    if( $(this).hasClass(activeClass) ) {
      $(this).removeClass(activeClass);
      hideDropdown($el);
    } else {
      showDropdown($el);
    }

  });

  function showDropdown ($el) {
    $parents.find('.site-nav--has-dropdown').removeClass(activeClass);
    $el.addClass(activeClass);
    var $list = ".child-list-" + $el.data('list');
    $subNav.addClass('show');
    $subNav.find('ul, .sub-nav-search').removeClass('show');
    $subNav.find($list).addClass('show');
    setTimeout(function() {
      Label.cache.$body.on('touchstart', function() {
        hideDropdown($el);
      });
    }, 250);
  }

  function hideDropdown ($el) {
    $subNav.removeClass('show');
  }

  $(document).mouseup(function (e)
  {
    var container = $('.sub-nav, .site-nav');
    if (!container.is(e.target) &&
        container.has(e.target).length === 0)
    {
      $parents.find('.site-nav--has-dropdown').removeClass("nav-hover");
      hideDropdown($('.site-nav--has-dropdown'));
    }
  });

};

Label.drawersInit = function () {
  Label.LeftDrawer = new Label.Drawers('NavDrawer', 'left');
  Label.RightDrawer = new Label.Drawers('CartDrawer', 'right', {
    'onDrawerOpen': ajaxCart.load
  });
};

Label.mobileNavToggle = function () {
  Label.cache.$mobileSubNavToggle.on('click', function(evt) {
    evt.preventDefault();
    $(this).parent().toggleClass('drawer-nav--expanded');
  });
};

Label.getHash = function () {
  return window.location.hash;
};

Label.updateHash = function (hash) {
  window.location.hash = '#' + hash;
  $('#' + hash).attr('tabindex', -1).focus();
};

Label.Image = {
    preload: function(e, t) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            this.loadImage(this.getSizedImageUrl(r, t))
        }
    },
    loadImage: function(e) {
        (new Image).src = e
    },
    imageSize: function(e) {
        var t = e.match(/.+_((?:pico|icon|thumb|small|compact|medium|large|grande)|\d{1,4}x\d{0,4}|x\d{1,4})[_\.@]/);
        return null !== t ? t[1] : null
    },
    getSizedImageUrl: function(e, t) {
        if (null == t) return e;
        if ("master" == t) return this.removeProtocol(e);
        var n = e.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i);
        if (null != n) {
            var r = e.split(n[0]),
                i = n[0];
            return this.removeProtocol(r[0] + "_" + t + i)
        }
        return null
    },
    removeProtocol: function(e) {
        return e.replace(/http(s)?:/, "")
    }
};

Label.variants = function() {
    //watch for changes in variants then fire product function
  $(document).on('change', '.single-option-selector', function() {
    //first get data and all current options in this product section
    var product = $(this).parents('section.section-product');
    var sectionId = product.attr('data-section-id');

    if (!$('#ProductJson-' + sectionId).html()) {
      return;
    }

    if (!$('#FirstVariant-' + sectionId).html()) {
      return;
    }
    
    console.log("Variant change");


    var productData= JSON.parse(document.getElementById('ProductJson-' + sectionId).innerHTML);
    var productImages = '';
    if( $('#ProductImages-' + sectionId).length) {
    	productImages = JSON.parse(document.getElementById('ProductImages-' + sectionId).innerHTML);
    }
    var selector = product.find('.product-form__variants');

    var variants = productData.variants;
    var money_format = product.data('money-format');
    var options = [];
    $(product).find('.single-option-selector').each(function() {
      var option = $(this).data('index');
      var val = $(this).val();
      var selected = {
        "index": option,
        "value": val
      }
      options.push(selected);
    });

    var variant = _.find(variants, function(variant) {
      return options.every(function(values) {
        return _.isEqual(variant[values.index], values.value);
      });
    });
    
    if(variant) {
      $(selector).find('option[value="' + variant.id +'"]').prop('selected', true);
          //update history - dont update if homepage
      if (history.replaceState && $('body').hasClass('template-product')) {
        var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?variant=' + variant.id;
        window.history.replaceState({path: newurl}, '', newurl);
      }
    }
    

    Label.productPage({
      money_format: money_format,
      variant: variant,
      firstLoad: false,
      product: productData,
      productContainer: product,
      productImages: productImages
    });

  });
};

Label.initProducts = function() {
  Label.variants();

  $('section.section-product').each(function() {


    var sectionId = $(this).attr('data-section-id');

    if (!$('#ProductJson-' + sectionId).html()) {
      return;
    }

    if (!$('#FirstVariant-' + sectionId).html()) {
      return;
    }

    var product = $(this);
    var productData= JSON.parse(document.getElementById('ProductJson-' + sectionId).innerHTML);
    var currentVariant = JSON.parse(document.getElementById('FirstVariant-' + sectionId).innerHTML);
    var productImages = '';
    if( $('#ProductImages-' + sectionId).length) {
    	productImages = JSON.parse(document.getElementById('ProductImages-' + sectionId).innerHTML);
    }
    var money_format = $(this).attr('data-money-format');

    if( $('body').hasClass('ajax-on') ) {
      ajaxCart.qtySelectors();
    }

    Label.productPage({
      money_format: money_format,
      variant: currentVariant,
      firstLoad: true,
      product: productData,
      productContainer: product,
      productImages: productImages
    });

  });
};


Label.productPage = function (options) {
  var moneyFormat = options.money_format,
      variant = options.variant,
      product = options.product;

  // Selectors
  var $productPrice = $('#ProductPrice'),
      $addToCart = $('.AddToCart'),
      $comparePrice = $('#ComparePrice'),
      $quantityElements = $('.quantity-selector, label + .js-qty'),
      $addToCartText = $('#AddToCartText');

  if (variant) {
    
    console.log("it's a variant!");
    // Update variant image, if one is set
    if (variant.featured_image) {
      var newImg = variant.featured_image;
      var thumbnail = Label.Image.getSizedImageUrl(newImg.src,'compact');
      var $img = $('img[src="' + thumbnail + '"]').click();
      console.log(thumbnail);
    }

    // Select a valid variant if available
    if (variant.available) {
      // Available, enable the submit button, change text, show quantity elements
      $addToCart.removeClass('disabled').prop('disabled', false);
      $addToCartText.html("Add to Cart");
      $quantityElements.show();
    } else {
      // Sold out, disable the submit button, change text, hide quantity elements
      $addToCart.addClass('disabled').prop('disabled', true);
      $addToCartText.html("Sold Out");
      $quantityElements.hide();
    }

    // Regardless of stock, update the product price
    $productPrice.html( Shopify.formatMoney(variant.price, moneyFormat) );

    // Also update and show the product's compare price if necessary
    if (variant.compare_at_price > variant.price) {
      $comparePrice
        .html("Compare at" + ' ' + Shopify.formatMoney(variant.compare_at_price, moneyFormat))
        .show();
    } else {
      $comparePrice.hide();
    }


  } else {
    // The variant doesn't exist, disable submit button.
    // This may be an error or notice that a specific variant is not available.
    // To only show available variants, implement linked product options:
    //   - http://docs.shopify.com/manual/configuration/store-customization/advanced-navigation/linked-product-options
    $addToCart.addClass('disabled').prop('disabled', true);
    $addToCartText.html("Unavailable");
    $quantityElements.hide();
  }
};

Label.productImageSwitch = function () {
  if (Label.cache.$thumbImages.length) {
    // Switch the main image with one of the thumbnails
    // Note: this does not change the variant selected, just the image
    Label.cache.$thumbImages.on('click', function(evt) {
      evt.preventDefault();

      var newImage = $(this).attr('href');
      var newImageHighRes = $(this).data('highres');
        Label.switchImage(newImage, null, Label.cache.$productImage, newImageHighRes);
    });
  }
};

Label.switchImage = function (src, imgObject, el, highRes) {
  // Make sure element is a jquery object
  if ( typeof Label.cache !== 'undefined' ) {
    Label.cache.$thumbImages.removeClass("active");
    Label.cache.$thumbImages.each(function() {
      var thumbSrc = $(this).attr("href");
      thumbSrc = thumbSrc.split('?')[0];
      var newSrc =  src.split('?')[0];
      if( thumbSrc === newSrc ) {
        $(this).addClass('active');
      }
    });
  }
  var $el = $(el);
  $el.attr('src', src);
  $('a.swipebox').attr('href', highRes);
};

Label.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"]').wrap('<div class="video-wrapper"></div>');
  $('iframe[src*="player.vimeo"]').wrap('<div class="video-wrapper"></div>');
};

Label.collectionViews = function () {
  if (Label.cache.$changeView.length) {
    Label.cache.$changeView.on('click', function() {
      var view = $(this).data('view'),
          url = document.URL,
          hasParams = url.indexOf('?') > -1;

      if (hasParams) {
        window.location = replaceUrlParam(url, 'view', view);
      } else {
        window.location = url + '?view=' + view;
      }
    });
  }
};

Label.loginForms = function() {
  function showRecoverPasswordForm() {
    Label.cache.$recoverPasswordForm.show();
    Label.cache.$customerLoginForm.hide();
  }

  function hideRecoverPasswordForm() {
    Label.cache.$recoverPasswordForm.hide();
    Label.cache.$customerLoginForm.show();
  }

  Label.cache.$recoverPasswordLink.on('click', function(evt) {
    evt.preventDefault();
    showRecoverPasswordForm();
  });

  Label.cache.$hideRecoverPasswordLink.on('click', function(evt) {
    evt.preventDefault();
    hideRecoverPasswordForm();
  });

  // Allow deep linking to recover password form
  if (Label.getHash() == '#recover') {
    showRecoverPasswordForm();
  }
};

Label.resetPasswordSuccess = function() {
  Label.cache.$passwordResetSuccess.show();
};

Label.AddToCartComplete = function(focused) {
  var _focused = focused;
  _focused.parents('.cta-content').find('.cta-complete').addClass('visible');
  setTimeout(function(){
    _focused.parents('.cta-content').find('.cta-complete').addClass('show');
  }, 50);
}

Label.collectionSorting = function() {

  Shopify.queryParams = {};
  if (location.search.length) {
    for (var aKeyValue, i = 0, aCouples = location.search.substr(1).split('&'); i < aCouples.length; i++) {
      aKeyValue = aCouples[i].split('=');
      if (aKeyValue.length > 1) {
        Shopify.queryParams[decodeURIComponent(aKeyValue[0])] = decodeURIComponent(aKeyValue[1]);
      }
    }
  }

  var $default = $('#SortBy').data('default');

  $('#SortBy')
    .val($default)
    .bind('change', function() {
      Shopify.queryParams.sort_by = jQuery(this).val();
      location.search = jQuery.param(Shopify.queryParams);
    }
  );

  var collFilters = jQuery('.coll-filter');
  var url = collFilters.data('url');
  var handle = collFilters.data('handle');
  collFilters.change(function() {
    var newTags = [];
    collFilters.each(function() {
      if (jQuery(this).val()) {
        newTags.push(jQuery(this).val());
      }
    });
    if (newTags.length) {
      var query = newTags.join('+');
      window.location.href = handle + '/' + query;
    }
    else {
      window.location.href = url;
    }
  });


};

/*============================================================================
  Drawer modules
  - Docs http://shopify.github.io/Timber/#drawers
==============================================================================*/
Label.Drawers = (function () {
  var Drawer = function (id, position, options) {
    var defaults = {
      close: '.js-drawer-close',
      open: '.js-drawer-open-' + position,
      openClass: 'js-drawer-open',
      dirOpenClass: 'js-drawer-open-' + position
    };

    this.$nodes = {
      parent: $('body, html'),
      page: $('#PageContainer'),
      moved: $('.is-moved-by-drawer')
    };

    this.config = $.extend(defaults, options);
    this.position = position;

    this.$drawer = $('#' + id);

    if (!this.$drawer.length) {
      return false;
    }

    this.drawerIsOpen = false;
    this.init();
  };

  Drawer.prototype.init = function () {
    $(this.config.open).on('click', $.proxy(this.open, this));
    this.$drawer.find(this.config.close).on('click', $.proxy(this.close, this));
  };

  Drawer.prototype.open = function (evt) {
    // Keep track if drawer was opened from a click, or called by another function
    var externalCall = false;

    // Prevent following href if link is clicked
    if (evt) {
      evt.preventDefault();
    } else {
      externalCall = true;
    }

    // Without this, the drawer opens, the click event bubbles up to $nodes.page
    // which closes the drawer.
    if (evt && evt.stopPropagation) {
      evt.stopPropagation();
      // save the source of the click, we'll focus to this on close
      this.$activeSource = $(evt.currentTarget);
    }

    if (this.drawerIsOpen && !externalCall) {
      return this.close();
    }

    // Add is-transitioning class to moved elements on open so drawer can have
    // transition for close animation
    this.$nodes.moved.addClass('is-transitioning');
    this.$drawer.prepareTransition();

    this.$nodes.parent.addClass(this.config.openClass + ' ' + this.config.dirOpenClass);
    this.drawerIsOpen = true;

    // Set focus on drawer
    this.trapFocus(this.$drawer, 'drawer_focus');

    // Run function when draw opens if set
    if (this.config.onDrawerOpen && typeof(this.config.onDrawerOpen) == 'function') {
      if (!externalCall) {
        this.config.onDrawerOpen();
      }
    }

    if (this.$activeSource && this.$activeSource.attr('aria-expanded')) {
      this.$activeSource.attr('aria-expanded', 'true');
    }

    // Lock scrolling on mobile
    this.$nodes.page.on('touchmove.drawer', function () {
      return false;
    });

    this.$nodes.page.on('click.drawer', $.proxy(function () {
      this.close();
      return false;
    }, this));
  };

  Drawer.prototype.close = function () {
    if (!this.drawerIsOpen) { // don't close a closed drawer
      return;
    }

    // deselect any focused form elements
    $(document.activeElement).trigger('blur');

    // Ensure closing transition is applied to moved elements, like the nav
    this.$nodes.moved.prepareTransition({ disableExisting: true });
    this.$drawer.prepareTransition({ disableExisting: true });

    this.$nodes.parent.removeClass(this.config.dirOpenClass + ' ' + this.config.openClass);

    this.drawerIsOpen = false;

    // Remove focus on drawer
    this.removeTrapFocus(this.$drawer, 'drawer_focus');

    this.$nodes.page.off('.drawer');
  };

  Drawer.prototype.trapFocus = function ($container, eventNamespace) {
    var eventName = eventNamespace ? 'focusin.' + eventNamespace : 'focusin';

    $container.attr('tabindex', '-1');

    $container.focus();

    $(document).on(eventName, function (evt) {
      if ($container[0] !== evt.target && !$container.has(evt.target).length) {
        $container.focus();
      }
    });
  };

  Drawer.prototype.removeTrapFocus = function ($container, eventNamespace) {
    var eventName = eventNamespace ? 'focusin.' + eventNamespace : 'focusin';

    $container.removeAttr('tabindex');
    $(document).off(eventName);
  };

  return Drawer;
})();

// Initialize Timber's JS on docready
$(Label.init);

$('body').on('contextmenu', 'img', function(event) {
	alert('All images and videos on this site including product images were either produced by Just Riding in our studio or they have been provided to us by a third party and then been professionally re worked by our graphic designers. Please ask if you would like to use any of our content. Unauthorised usage is not permitted.');
	return false;
});

! function(e, t, n) {
    var i = window.matchMedia;
    "undefined" != typeof module && module.exports ? module.exports = n(i) : "function" == typeof define && define.amd ? define(function() {
        return t[e] = n(i)
    }) : t[e] = n(i)
}("enquire", this, function(e) {
    "use strict";

    function t(e, t) {
        var n, i = 0,
            o = e.length;
        for (i; o > i && (n = t(e[i], i), n !== !1); i++);
    }

    function n(e) {
        return "[object Array]" === Object.prototype.toString.apply(e)
    }

    function i(e) {
        return "function" == typeof e
    }

    function o(e) {
        this.options = e, !e.deferSetup && this.setup()
    }

    function r(t, n) {
        this.query = t, this.isUnconditional = n, this.handlers = [], this.mql = e(t);
        var i = this;
        this.listener = function(e) {
            i.mql = e, i.assess()
        }, this.mql.addListener(this.listener)
    }

    function s() {
        if (!e) throw new Error("matchMedia not present, legacy browsers require a polyfill");
        this.queries = {}, this.browserIsIncapable = !e("only all").matches
    }
    return o.prototype = {
        setup: function() {
            this.options.setup && this.options.setup(), this.initialised = !0
        },
        on: function() {
            !this.initialised && this.setup(), this.options.match && this.options.match()
        },
        off: function() {
            this.options.unmatch && this.options.unmatch()
        },
        destroy: function() {
            this.options.destroy ? this.options.destroy() : this.off()
        },
        equals: function(e) {
            return this.options === e || this.options.match === e
        }
    }, r.prototype = {
        addHandler: function(e) {
            var t = new o(e);
            this.handlers.push(t), this.matches() && t.on()
        },
        removeHandler: function(e) {
            var n = this.handlers;
            t(n, function(t, i) {
                return t.equals(e) ? (t.destroy(), !n.splice(i, 1)) : void 0
            })
        },
        matches: function() {
            return this.mql.matches || this.isUnconditional
        },
        clear: function() {
            t(this.handlers, function(e) {
                e.destroy()
            }), this.mql.removeListener(this.listener), this.handlers.length = 0
        },
        assess: function() {
            var e = this.matches() ? "on" : "off";
            t(this.handlers, function(t) {
                t[e]()
            })
        }
    }, s.prototype = {
        register: function(e, o, s) {
            var a = this.queries,
                l = s && this.browserIsIncapable;
            return a[e] || (a[e] = new r(e, l)), i(o) && (o = {
                match: o
            }), n(o) || (o = [o]), t(o, function(t) {
                i(t) && (t = {
                    match: t
                }), a[e].addHandler(t)
            }), this
        },
        unregister: function(e, t) {
            var n = this.queries[e];
            return n && (t ? n.removeHandler(t) : (n.clear(), delete this.queries[e])), this
        }
    }, new s
});

(function(e) {
    var t = {
        url: !1,
        callback: !1,
        target: !1,
        duration: 120,
        on: "mouseover",
        touch: !0,
        onZoomIn: !1,
        onZoomOut: !1,
        magnify: 1
    };
    e.zoom = function(t, n, i, o) {
        var r, s, a, l, u, c, d, p = e(t),
            f = p.css("position"),
            h = e(n);
        return p.css("position", /(absolute|fixed)/.test(f) ? f : "relative"), p.css("overflow", "hidden"), i.style.width = i.style.height = "", e(i).addClass("zoomImg").css({
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            width: i.width * o,
            height: i.height * o,
            border: "none",
            maxWidth: "none",
            maxHeight: "none"
        }).appendTo(t), {
            init: function() {
                s = p.outerWidth(), r = p.outerHeight(), n === p[0] ? (l = s, a = r) : (l = h.outerWidth(), a = h.outerHeight()), u = (i.width - s) / l, c = (i.height - r) / a, d = h.offset()
            },
            move: function(e) {
                var t = e.pageX - d.left,
                    n = e.pageY - d.top;
                n = Math.max(Math.min(n, a), 0), t = Math.max(Math.min(t, l), 0), i.style.left = t * -u + "px", i.style.top = n * -c + "px"
            }
        }
    }, e.fn.zoom = function(n) {
        return this.each(function() {
            var i, o = e.extend({}, t, n || {}),
                r = o.target || this,
                s = this,
                a = e(s),
                l = e(r),
                u = document.createElement("img"),
                c = e(u),
                d = "mousemove.zoom",
                p = !1,
                f = !1;
            (o.url || (i = a.find("img"), i[0] && (o.url = i.data("src") || i.attr("src")), o.url)) && (! function() {
                var e = l.css("position"),
                    t = l.css("overflow");
                a.one("zoom.destroy", function() {
                    a.off(".zoom"), l.css("position", e), l.css("overflow", t), c.remove()
                })
            }(), u.onload = function() {
                function t(t) {
                    i.init(), i.move(t), c.stop().fadeTo(e.support.opacity ? o.duration : 0, 1, !!e.isFunction(o.onZoomIn) && o.onZoomIn.call(u))
                }

                function n() {
                    c.stop().fadeTo(o.duration, 0, !!e.isFunction(o.onZoomOut) && o.onZoomOut.call(u))
                }
                var i = e.zoom(r, s, u, o.magnify);
                "grab" === o.on ? a.on("mousedown.zoom", function(o) {
                    1 === o.which && (e(document).one("mouseup.zoom", function() {
                        n(), e(document).off(d, i.move)
                    }), t(o), e(document).on(d, i.move), o.preventDefault())
                }) : "click" === o.on ? a.on("click.zoom", function(o) {
                    return p ? void 0 : (p = !0, t(o), e(document).on(d, i.move), e(document).one("click.zoom", function() {
                        n(), p = !1, e(document).off(d, i.move)
                    }), !1)
                }) : "toggle" === o.on ? a.on("click.zoom", function(e) {
                    p ? n() : t(e), p = !p
                }) : "mouseover" === o.on && (i.init(), a.on("mouseenter.zoom", t).on("mouseleave.zoom", n).on(d, i.move)), o.touch && a.on("touchstart.zoom", function(e) {
                    e.preventDefault(), f ? (f = !1, n()) : (f = !0, t(e.originalEvent.touches[0] || e.originalEvent.changedTouches[0]))
                }).on("touchmove.zoom", function(e) {
                    e.preventDefault(), i.move(e.originalEvent.touches[0] || e.originalEvent.changedTouches[0])
                }).on("touchend.zoom", function(e) {
                    e.preventDefault(), f && (f = !1, n())
                }), e.isFunction(o.callback) && o.callback.call(u)
            }, u.src = o.url)
        })
    }, e.fn.zoom.defaults = t
})(window.jQuery);

(function() {
    function e(e, t) {
        for (var n = -1, i = t.length, o = e.length; ++n < i;) e[o + n] = t[n];
        return e
    }

    function t(e, t, n) {
        for (var i = -1, o = e.length; ++i < o;) {
            var r = e[i],
                s = t(r);
            if (null != s && (a === le ? s === s : n(s, a))) var a = s,
                l = r
        }
        return l
    }

    function n(e, t, n) {
        var i;
        return n(e, function(e, n, o) {
            return t(e, n, o) ? (i = e, !1) : void 0
        }), i
    }

    function i(e, t, n, i, o) {
        return o(e, function(e, o, r) {
            n = i ? (i = !1, e) : t(n, e, o, r)
        }), n
    }

    function o(e, t) {
        return T(t, function(t) {
            return e[t]
        })
    }

    function r(e) {
        return e && e.Object === Object ? e : null
    }

    function s(e) {
        return fe[e]
    }

    function a(e) {
        var t = !1;
        if (null != e && "function" != typeof e.toString) try {
            t = !!(e + "")
        } catch (e) {}
        return t
    }

    function l(e, t) {
        return e = "number" == typeof e || pe.test(e) ? +e : -1, e > -1 && 0 == e % 1 && (null == t ? 9007199254740991 : t) > e
    }

    function u(e) {
        if (Z(e) && !Re(e)) {
            if (e instanceof c) return e;
            if (Ce.call(e, "__wrapped__")) {
                var t = new c(e.__wrapped__, e.__chain__);
                return t.__actions__ = A(e.__actions__), t
            }
        }
        return new c(e)
    }

    function c(e, t) {
        this.__wrapped__ = e, this.__actions__ = [], this.__chain__ = !!t
    }

    function d(e, t, n, i) {
        var o;
        return (o = e === le) || (o = Te[n], o = (e === o || e !== e && o !== o) && !Ce.call(i, n)), o ? t : e
    }

    function p(e) {
        return J(e) ? Ne(e) : {}
    }

    function f(e, t, n) {
        if ("function" != typeof e) throw new TypeError("Expected a function");
        return setTimeout(function() {
            e.apply(le, n)
        }, t)
    }

    function h(e, t) {
        var n = !0;
        return Pe(e, function(e, i, o) {
            return n = !!t(e, i, o)
        }), n
    }

    function v(e, t) {
        var n = [];
        return Pe(e, function(e, i, o) {
            t(e, i, o) && n.push(e)
        }), n
    }

    function g(t, n, i, o) {
        o || (o = []);
        for (var r = -1, s = t.length; ++r < s;) {
            var a = t[r];
            n > 0 && Z(a) && V(a) && (i || Re(a) || Y(a)) ? n > 1 ? g(a, n - 1, i, o) : e(o, a) : i || (o[o.length] = a)
        }
        return o
    }

    function m(e, t) {
        return e && Le(e, t, ie)
    }

    function y(e, t) {
        return v(t, function(t) {
            return G(e[t])
        })
    }

    function w(e, t, n, i, o) {
        return e === t || (null == e || null == t || !J(e) && !Z(t) ? e !== e && t !== t : b(e, t, w, n, i, o))
    }

    function b(e, t, n, i, o, r) {
        var s = Re(e),
            l = Re(t),
            u = "[object Array]",
            c = "[object Array]";
        s || (u = $e.call(e), "[object Arguments]" == u && (u = "[object Object]")), l || (c = $e.call(t), "[object Arguments]" == c && (c = "[object Object]"));
        var d = "[object Object]" == u && !a(e),
            l = "[object Object]" == c && !a(t);
        return !(c = u == c) || s || d ? 2 & o || (u = d && Ce.call(e, "__wrapped__"), l = l && Ce.call(t, "__wrapped__"), !u && !l) ? !!c && (r || (r = []), (u = M(r, function(t) {
            return t[0] === e
        })) && u[1] ? u[1] == t : (r.push([e, t]), t = (s ? P : _)(e, t, n, i, o, r), r.pop(), t)) : n(u ? e.value() : e, l ? t.value() : t, i, o, r) : L(e, t, u)
    }

    function x(e) {
        var t = typeof e;
        return "function" == t ? e : null == e ? se : ("object" == t ? C : $)(e)
    }

    function k(e) {
        e = null == e ? e : Object(e);
        var t, n = [];
        for (t in e) n.push(t);
        return n
    }

    function T(e, t) {
        var n = -1,
            i = V(e) ? Array(e.length) : [];
        return Pe(e, function(e, o, r) {
            i[++n] = t(e, o, r)
        }), i
    }

    function C(e) {
        var t = ie(e);
        return function(n) {
            var i = t.length;
            if (null == n) return !i;
            for (n = Object(n); i--;) {
                var o = t[i];
                if (!(o in n && w(e[o], n[o], le, 3))) return !1
            }
            return !0
        }
    }

    function S(e, t) {
        return e = Object(e), W(t, function(t, n) {
            return n in e && (t[n] = e[n]), t
        }, {})
    }

    function $(e) {
        return function(t) {
            return null == t ? le : t[e]
        }
    }

    function E(e, t, n) {
        var i = -1,
            o = e.length;
        for (0 > t && (t = -t > o ? 0 : o + t), n = n > o ? o : n, 0 > n && (n += o), o = t > n ? 0 : n - t >>> 0, t >>>= 0, n = Array(o); ++i < o;) n[i] = e[i + t];
        return n
    }

    function A(e) {
        return E(e, 0, e.length)
    }

    function j(e, t) {
        var n;
        return Pe(e, function(e, i, o) {
            return n = t(e, i, o), !n
        }), !!n
    }

    function N(t, n) {
        return W(n, function(t, n) {
            return n.func.apply(n.thisArg, e([t], n.args))
        }, t)
    }

    function D(e, t, n, i) {
        n || (n = {});
        for (var o = -1, r = t.length; ++o < r;) {
            var s = t[o],
                a = i ? i(n[s], e[s], s, n, e) : e[s],
                l = n,
                u = l[s];
            Ce.call(l, s) && (u === a || u !== u && a !== a) && (a !== le || s in l) || (l[s] = a)
        }
        return n
    }

    function O(e) {
        return U(function(t, n) {
            var i = -1,
                o = n.length,
                r = o > 1 ? n[o - 1] : le,
                r = "function" == typeof r ? (o--, r) : le;
            for (t = Object(t); ++i < o;) {
                var s = n[i];
                s && e(t, s, i, r)
            }
            return t
        })
    }

    function H(e) {
        return function() {
            var t = arguments,
                n = p(e.prototype),
                t = e.apply(n, t);
            return J(t) ? t : n
        }
    }

    function q(e, t, n) {
        function i() {
            for (var r = -1, s = arguments.length, a = -1, l = n.length, u = Array(l + s), c = this && this !== xe && this instanceof i ? o : e; ++a < l;) u[a] = n[a];
            for (; s--;) u[a++] = arguments[++r];
            return c.apply(t, u)
        }
        if ("function" != typeof e) throw new TypeError("Expected a function");
        var o = H(e);
        return i
    }

    function P(e, t, n, i, o, r) {
        var s = -1,
            a = 1 & o,
            l = e.length,
            u = t.length;
        if (l != u && !(2 & o && u > l)) return !1;
        for (u = !0; ++s < l;) {
            var c = e[s],
                d = t[s];
            if (void 0 !== le) {
                u = !1;
                break
            }
            if (a) {
                if (!j(t, function(e) {
                        return c === e || n(c, e, i, o, r)
                    })) {
                    u = !1;
                    break
                }
            } else if (c !== d && !n(c, d, i, o, r)) {
                u = !1;
                break
            }
        }
        return u
    }

    function L(e, t, n) {
        switch (n) {
            case "[object Boolean]":
            case "[object Date]":
                return +e == +t;
            case "[object Error]":
                return e.name == t.name && e.message == t.message;
            case "[object Number]":
                return e != +e ? t != +t : e == +t;
            case "[object RegExp]":
            case "[object String]":
                return e == t + ""
        }
        return !1
    }

    function _(e, t, n, i, o, r) {
        var s = 2 & o,
            a = ie(e),
            l = a.length,
            u = ie(t).length;
        if (l != u && !s) return !1;
        for (var c = l; c--;) {
            var d = a[c];
            if (!(s ? d in t : Ce.call(t, d))) return !1
        }
        for (u = !0; ++c < l;) {
            var d = a[c],
                p = e[d],
                f = t[d];
            if (void 0 !== le || p !== f && !n(p, f, i, o, r)) {
                u = !1;
                break
            }
            s || (s = "constructor" == d)
        }
        return u && !s && (n = e.constructor, i = t.constructor, n != i && "constructor" in e && "constructor" in t && !("function" == typeof n && n instanceof n && "function" == typeof i && i instanceof i) && (u = !1)), u
    }

    function z(e) {
        var t = e ? e.length : le;
        if (Q(t) && (Re(e) || ee(e) || Y(e))) {
            e = String;
            for (var n = -1, i = Array(t); ++n < t;) i[n] = e(n);
            t = i
        } else t = null;
        return t
    }

    function I(e) {
        var t = e && e.constructor,
            t = G(t) && t.prototype || Te;
        return e === t
    }

    function F(e) {
        return e ? e[0] : le
    }

    function M(e, t) {
        return n(e, x(t), Pe)
    }

    function R(e, t) {
        return Pe(e, "function" == typeof t ? t : se)
    }

    function W(e, t, n) {
        return i(e, x(t), n, 3 > arguments.length, Pe)
    }

    function B(e, t) {
        var n;
        if ("function" != typeof t) throw new TypeError("Expected a function");
        return e = We(e),
            function() {
                return 0 < --e && (n = t.apply(this, arguments)), 1 >= e && (t = le), n
            }
    }

    function U(e) {
        var t;
        if ("function" != typeof e) throw new TypeError("Expected a function");
        return t = qe(t === le ? e.length - 1 : We(t), 0),
            function() {
                for (var n = arguments, i = -1, o = qe(n.length - t, 0), r = Array(o); ++i < o;) r[i] = n[t + i];
                for (o = Array(t + 1), i = -1; ++i < t;) o[i] = n[i];
                return o[t] = r, e.apply(this, o)
            }
    }

    function X(e, t) {
        return e > t
    }

    function Y(e) {
        return Z(e) && V(e) && Ce.call(e, "callee") && (!De.call(e, "callee") || "[object Arguments]" == $e.call(e))
    }

    function V(e) {
        return null != e && !("function" == typeof e && G(e)) && Q(_e(e))
    }

    function G(e) {
        return e = J(e) ? $e.call(e) : "", "[object Function]" == e || "[object GeneratorFunction]" == e
    }

    function Q(e) {
        return "number" == typeof e && e > -1 && 0 == e % 1 && 9007199254740991 >= e
    }

    function J(e) {
        var t = typeof e;
        return !!e && ("object" == t || "function" == t)
    }

    function Z(e) {
        return !!e && "object" == typeof e
    }

    function K(e) {
        return "number" == typeof e || Z(e) && "[object Number]" == $e.call(e)
    }

    function ee(e) {
        return "string" == typeof e || !Re(e) && Z(e) && "[object String]" == $e.call(e)
    }

    function te(e, t) {
        return t > e
    }

    function ne(e) {
        return "string" == typeof e ? e : null == e ? "" : e + ""
    }

    function ie(e) {
        var t = I(e);
        if (!t && !V(e)) return He(Object(e));
        var n, i = z(e),
            o = !!i,
            i = i || [],
            r = i.length;
        for (n in e) !Ce.call(e, n) || o && ("length" == n || l(n, r)) || t && "constructor" == n || i.push(n);
        return i
    }

    function oe(e) {
        for (var t = -1, n = I(e), i = k(e), o = i.length, r = z(e), s = !!r, r = r || [], a = r.length; ++t < o;) {
            var u = i[t];
            s && ("length" == u || l(u, a)) || "constructor" == u && (n || !Ce.call(e, u)) || r.push(u)
        }
        return r
    }

    function re(e) {
        return e ? o(e, ie(e)) : []
    }

    function se(e) {
        return e
    }

    function ae(t, n, i) {
        var o = ie(n),
            r = y(n, o);
        null != i || J(n) && (r.length || !o.length) || (i = n, n = t, t = this, r = y(n, ie(n)));
        var s = !(J(i) && "chain" in i) || i.chain,
            a = G(t);
        return Pe(r, function(i) {
            var o = n[i];
            t[i] = o, a && (t.prototype[i] = function() {
                var n = this.__chain__;
                if (s || n) {
                    var i = t(this.__wrapped__);
                    return (i.__actions__ = A(this.__actions__)).push({
                        func: o,
                        args: arguments,
                        thisArg: t
                    }), i.__chain__ = n, i
                }
                return o.apply(t, e([this.value()], arguments))
            })
        }), t
    }
    var le, ue = 1 / 0,
        ce = /[&<>"'`]/g,
        de = RegExp(ce.source),
        pe = /^(?:0|[1-9]\d*)$/,
        fe = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "`": "&#96;"
        },
        he = {
            function: !0,
            object: !0
        },
        ve = he[typeof exports] && exports && !exports.nodeType ? exports : le,
        ge = he[typeof module] && module && !module.nodeType ? module : le,
        me = ge && ge.exports === ve ? ve : le,
        ye = r(he[typeof self] && self),
        we = r(he[typeof window] && window),
        be = r(he[typeof this] && this),
        xe = r(ve && ge && "object" == typeof global && global) || we !== (be && be.window) && we || ye || be || Function("return this")(),
        ke = Array.prototype,
        Te = Object.prototype,
        Ce = Te.hasOwnProperty,
        Se = 0,
        $e = Te.toString,
        Ee = xe._,
        Ae = xe.Reflect,
        je = Ae ? Ae.f : le,
        Ne = Object.create,
        De = Te.propertyIsEnumerable,
        Oe = xe.isFinite,
        He = Object.keys,
        qe = Math.max,
        Pe = function(e, t) {
            return function(n, i) {
                if (null == n) return n;
                if (!V(n)) return e(n, i);
                for (var o = n.length, r = t ? o : -1, s = Object(n);
                    (t ? r-- : ++r < o) && !1 !== i(s[r], r, s););
                return n
            }
        }(m),
        Le = function(e) {
            return function(t, n, i) {
                var o = -1,
                    r = Object(t);
                i = i(t);
                for (var s = i.length; s--;) {
                    var a = i[e ? s : ++o];
                    if (!1 === n(r[a], a, r)) break
                }
                return t
            }
        }();
    je && !De.call({
        valueOf: 1
    }, "valueOf") && (k = function(e) {
        e = je(e);
        for (var t, n = []; !(t = e.next()).done;) n.push(t.value);
        return n
    });
    var _e = $("length"),
        ze = U(function(t, n) {
            return Re(t) || (t = null == t ? [] : [Object(t)]), g(n, 1), e(A(t), re)
        }),
        Ie = U(function(e, t, n) {
            return q(e, t, n)
        }),
        Fe = U(function(e, t) {
            return f(e, 1, t)
        }),
        Me = U(function(e, t, n) {
            return f(e, Be(t) || 0, n)
        }),
        Re = Array.isArray,
        We = Number,
        Be = Number,
        Ue = O(function(e, t) {
            D(t, ie(t), e)
        }),
        Xe = O(function(e, t) {
            D(t, oe(t), e)
        }),
        Ye = O(function(e, t, n, i) {
            D(t, oe(t), e, i)
        }),
        Ve = U(function(e) {
            return e.push(le, d), Ye.apply(le, e)
        }),
        Ge = U(function(e, t) {
            return null == e ? {} : S(e, g(t, 1))
        }),
        Qe = x;
    c.prototype = p(u.prototype), c.prototype.constructor = c, u.assignIn = Xe, u.before = B, u.bind = Ie, u.chain = function(e) {
        return e = u(e), e.__chain__ = !0, e
    }, u.compact = function(e) {
        return v(e, Boolean)
    }, u.concat = ze, u.create = function(e, t) {
        var n = p(e);
        return t ? Ue(n, t) : n
    }, u.defaults = Ve, u.defer = Fe, u.delay = Me, u.filter = function(e, t) {
        return v(e, x(t))
    }, u.flatten = function(e) {
        return e && e.length ? g(e, 1) : []
    }, u.flattenDeep = function(e) {
        return e && e.length ? g(e, ue) : []
    }, u.iteratee = Qe, u.keys = ie, u.map = function(e, t) {
        return T(e, x(t))
    }, u.matches = function(e) {
        return C(Ue({}, e))
    }, u.mixin = ae, u.negate = function(e) {
        if ("function" != typeof e) throw new TypeError("Expected a function");
        return function() {
            return !e.apply(this, arguments)
        }
    }, u.once = function(e) {
        return B(2, e)
    }, u.pick = Ge, u.slice = function(e, t, n) {
        var i = e ? e.length : 0;
        return n = n === le ? i : +n, i ? E(e, null == t ? 0 : +t, n) : []
    }, u.sortBy = function(e, t) {
        var n = 0;
        return t = x(t), T(T(e, function(e, i, o) {
            return {
                c: e,
                b: n++,
                a: t(e, i, o)
            }
        }).sort(function(e, t) {
            var n;
            e: {
                n = e.a;
                var i = t.a;
                if (n !== i) {
                    var o = null === n,
                        r = n === le,
                        s = n === n,
                        a = null === i,
                        l = i === le,
                        u = i === i;
                    if (n > i && !a || !s || o && !l && u || r && u) {
                        n = 1;
                        break e
                    }
                    if (i > n && !o || !u || a && !r && s || l && s) {
                        n = -1;
                        break e
                    }
                }
                n = 0
            }
            return n || e.b - t.b
        }), $("c"))
    }, u.tap = function(e, t) {
        return t(e), e
    }, u.thru = function(e, t) {
        return t(e)
    }, u.toArray = function(e) {
        return V(e) ? e.length ? A(e) : [] : re(e)
    }, u.values = re, u.extend = Xe, ae(u, u), u.clone = function(e) {
        return J(e) ? Re(e) ? A(e) : D(e, ie(e)) : e
    }, u.escape = function(e) {
        return (e = ne(e)) && de.test(e) ? e.replace(ce, s) : e
    }, u.every = function(e, t, n) {
        return t = n ? le : t, h(e, x(t))
    }, u.find = M, u.forEach = R, u.has = function(e, t) {
        return null != e && Ce.call(e, t)
    }, u.head = F, u.identity = se, u.indexOf = function(e, t, n) {
        var i = e ? e.length : 0;
        n = "number" == typeof n ? 0 > n ? qe(i + n, 0) : n : 0, n = (n || 0) - 1;
        for (var o = t === t; ++n < i;) {
            var r = e[n];
            if (o ? r === t : r !== r) return n
        }
        return -1
    }, u.isArguments = Y, u.isArray = Re, u.isBoolean = function(e) {
        return !0 === e || !1 === e || Z(e) && "[object Boolean]" == $e.call(e)
    }, u.isDate = function(e) {
        return Z(e) && "[object Date]" == $e.call(e)
    }, u.isEmpty = function(e) {
        if (V(e) && (Re(e) || ee(e) || G(e.splice) || Y(e))) return !e.length;
        for (var t in e)
            if (Ce.call(e, t)) return !1;
        return !0
    }, u.isEqual = function(e, t) {
        return w(e, t)
    }, u.isFinite = function(e) {
        return "number" == typeof e && Oe(e)
    }, u.isFunction = G, u.isNaN = function(e) {
        return K(e) && e != +e
    }, u.isNull = function(e) {
        return null === e
    }, u.isNumber = K, u.isObject = J, u.isRegExp = function(e) {
        return J(e) && "[object RegExp]" == $e.call(e)
    }, u.isString = ee, u.isUndefined = function(e) {
        return e === le
    }, u.last = function(e) {
        var t = e ? e.length : 0;
        return t ? e[t - 1] : le
    }, u.max = function(e) {
        return e && e.length ? t(e, se, X) : le
    }, u.min = function(e) {
        return e && e.length ? t(e, se, te) : le
    }, u.noConflict = function() {
        return xe._ === this && (xe._ = Ee), this
    }, u.noop = function() {}, u.reduce = W, u.result = function(e, t, n) {
        return t = null == e ? le : e[t], t === le && (t = n), G(t) ? t.call(e) : t
    }, u.size = function(e) {
        return null == e ? 0 : (e = V(e) ? e : ie(e), e.length)
    }, u.some = function(e, t, n) {
        return t = n ? le : t, j(e, x(t))
    }, u.uniqueId = function(e) {
        var t = ++Se;
        return ne(e) + t
    }, u.each = R, u.first = F, ae(u, function() {
        var e = {};
        return m(u, function(t, n) {
            Ce.call(u.prototype, n) || (e[n] = t)
        }), e
    }(), {
        chain: !1
    }), u.VERSION = "4.5.1", Pe("pop join replace reverse split push shift sort splice unshift".split(" "), function(e) {
        var t = (/^(?:replace|split)$/.test(e) ? String.prototype : ke)[e],
            n = /^(?:push|sort|unshift)$/.test(e) ? "tap" : "thru",
            i = /^(?:pop|join|replace|shift)$/.test(e);
        u.prototype[e] = function() {
            var e = arguments;
            return i && !this.__chain__ ? t.apply(this.value(), e) : this[n](function(n) {
                return t.apply(n, e)
            })
        }
    }), u.prototype.toJSON = u.prototype.valueOf = u.prototype.value = function() {
        return N(this.__wrapped__, this.__actions__)
    }, (we || ye || {})._ = u, "function" == typeof define && "object" == typeof define.amd && define.amd ? define(function() {
        return u
    }) : ve && ge ? (me && ((ge.exports = u)._ = u), ve._ = u) : xe._ = u
}.call(this));

(function(e) {
    "use strict";
    "function" == typeof define && define.amd ? define(["jquery"], e) : "undefined" != typeof exports ? module.exports = e(require("jquery")) : e(jQuery)
})(function(e) {
    "use strict";
    var t = window.Slick || {};
    t = function() {
        function t(t, i) {
            var o, r = this;
            r.defaults = {
                accessibility: !0,
                appendArrows: e(t),
                appendDots: e(t),
                arrows: !0,
                asNavFor: null,
                prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous slide" tabindex="0" role="button">Previous slide</button>',
                nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next slide" tabindex="0" role="button">Next slide</button>',
                autoplay: !1,
                autoplaySpeed: 3e3,
                cssEase: "ease",
                customPaging: function(t, n) {
                    return e('<button type="button" data-role="none" role="button" tabindex="0" />').text("Slide " + (n + 1))
                },
                dots: !1,
                dotsClass: "slick-dots",
                draggable: !0,
                easing: "linear",
                edgeFriction: .35,
                fade: !1,
                focusOnSelect: !1,
                initialSlide: 0,
                lazyLoad: !0,
                pauseOnHover: !0,
                pauseOnFocus: !0,
                pauseOnDotsHover: !1,
                respondTo: "window",
                rows: 1,
                rtl: !1,
                slide: "",
                slidesPerRow: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                swipe: !0,
                swipeToSlide: !1,
                touchMove: !0,
                touchThreshold: 5,
                useCSS: !0,
                useTransform: !0,
                waitForAnimate: !0,
                zIndex: 1e3
            }, r.initials = {
                animating: !1,
                dragging: !1,
                autoPlayTimer: null,
                currentDirection: 0,
                currentLeft: null,
                currentSlide: 0,
                direction: 1,
                $dots: null,
                listWidth: null,
                listHeight: null,
                loadIndex: 0,
                $nextArrow: null,
                $prevArrow: null,
                slideCount: null,
                slideWidth: null,
                $slideTrack: null,
                $slides: null,
                sliding: !1,
                slideOffset: 0,
                swipeLeft: null,
                $list: null,
                touchObject: {},
                transformsEnabled: !1,
                unslicked: !1
            }, e.extend(r, r.initials), r.activeBreakpoint = null, r.animType = null, r.animProp = null, r.breakpoints = [], r.breakpointSettings = [], r.cssTransitions = !1, r.focussed = !1, r.interrupted = !1, r.hidden = "hidden", r.paused = !0, r.positionProp = null, r.respondTo = null, r.rowCount = 1, r.shouldClick = !0, r.$slider = e(t), r.$slidesCache = null, r.transformType = null, r.transitionType = null, r.visibilityChange = "visibilitychange", r.windowWidth = 0, r.windowTimer = null, o = e(t).data("slick") || {}, r.options = e.extend({}, r.defaults, i, o), r.currentSlide = r.options.initialSlide, r.originalSettings = r.options, "undefined" != typeof document.mozHidden ? (r.hidden = "mozHidden", r.visibilityChange = "mozvisibilitychange") : "undefined" != typeof document.webkitHidden && (r.hidden = "webkitHidden", r.visibilityChange = "webkitvisibilitychange"), r.autoPlay = e.proxy(r.autoPlay, r), r.autoPlayClear = e.proxy(r.autoPlayClear, r), r.autoPlayIterator = e.proxy(r.autoPlayIterator, r), r.changeSlide = e.proxy(r.changeSlide, r), r.clickHandler = e.proxy(r.clickHandler, r), r.selectHandler = e.proxy(r.selectHandler, r), r.setPosition = e.proxy(r.setPosition, r), r.swipeHandler = e.proxy(r.swipeHandler, r), r.dragHandler = e.proxy(r.dragHandler, r), r.keyHandler = e.proxy(r.keyHandler, r), r.instanceUid = n++, r.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/, r.init(!0)
        }
        var n = 0;
        return t
    }(), t.prototype.activateADA = function() {
        var e = this;
        e.$slides.add(e.$slideTrack.find(".slick-cloned")).attr({
            "aria-hidden": "true",
            tabindex: "-1"
        }).find("a, input, button, select").attr({
            tabindex: "-1"
        }), e.$slideTrack.find(".slick-active").attr({
            "aria-hidden": "false"
        }).find("a, input, button, select").attr({
            tabindex: "0"
        })
    }, t.prototype.animateSlide = function(t, n) {
        var i = {},
            o = this;
        o.options.rtl === !0 && (t = -t), o.transformsEnabled === !1 ? o.$slideTrack.animate({
            left: t
        }, o.options.speed, o.options.easing, n) : o.cssTransitions === !1 ? (o.options.rtl === !0 && (o.currentLeft = -o.currentLeft), e({
            animStart: o.currentLeft
        }).animate({
            animStart: t
        }, {
            duration: o.options.speed,
            easing: o.options.easing,
            step: function(e) {
                e = Math.ceil(e), i[o.animType] = "translate(" + e + "px, 0px)", o.$slideTrack.css(i)
            },
            complete: function() {
                n && n.call()
            }
        })) : (o.applyTransition(), t = Math.ceil(t), i[o.animType] = "translate3d(" + t + "px, 0px, 0px)", o.$slideTrack.css(i), n && setTimeout(function() {
            o.disableTransition(), n.call()
        }, o.options.speed))
    }, t.prototype.getNavTarget = function() {
        var t = this,
            n = t.options.asNavFor;
        return n && null !== n && (n = e(n).not(t.$slider)), n
    }, t.prototype.asNavFor = function(t) {
        var n = this,
            i = n.getNavTarget();
        null !== i && "object" == typeof i && i.each(function() {
            var n = e(this).slick("getSlick");
            n.unslicked || n.slideHandler(t, !0)
        })
    }, t.prototype.applyTransition = function(e) {
        var t = this,
            n = {};
        t.options.fade === !1 ? n[t.transitionType] = t.transformType + " " + t.options.speed + "ms " + t.options.cssEase : n[t.transitionType] = "opacity " + t.options.speed + "ms " + t.options.cssEase, t.options.fade === !1 ? t.$slideTrack.css(n) : t.$slides.eq(e).css(n)
    }, t.prototype.autoPlay = function() {
        var e = this;
        e.autoPlayClear(), e.slideCount > e.options.slidesToShow && (e.autoPlayTimer = setInterval(e.autoPlayIterator, e.options.autoplaySpeed))
    }, t.prototype.autoPlayClear = function() {
        var e = this;
        e.autoPlayTimer && clearInterval(e.autoPlayTimer)
    }, t.prototype.autoPlayIterator = function() {
        var e = this,
            t = e.currentSlide + e.options.slidesToScroll;
        e.paused || e.interrupted || e.focussed || e.slideHandler(t)
    }, t.prototype.buildArrows = function() {
        var t = this;
        t.options.arrows === !0 && (t.$prevArrow = e(t.options.prevArrow).addClass("slick-arrow"), t.$nextArrow = e(t.options.nextArrow).addClass("slick-arrow"), t.slideCount > t.options.slidesToShow ? (t.$prevArrow.removeClass("slick-hidden").removeAttr("aria-hidden tabindex"), t.$nextArrow.removeClass("slick-hidden").removeAttr("aria-hidden tabindex"), t.htmlExpr.test(t.options.prevArrow) && t.$prevArrow.prependTo(t.options.appendArrows), t.htmlExpr.test(t.options.nextArrow) && t.$nextArrow.appendTo(t.options.appendArrows), t.options.infinite !== !0 && t.$prevArrow.addClass("slick-disabled").attr("aria-disabled", "true")) : t.$prevArrow.add(t.$nextArrow).addClass("slick-hidden").attr({
            "aria-disabled": "true",
            tabindex: "-1"
        }))
    }, t.prototype.buildDots = function() {
        var t, n, i = this;
        if (i.options.dots === !0 && i.slideCount > i.options.slidesToShow) {
            for (i.$slider.addClass("slick-dotted"), n = e("<ul />").addClass(i.options.dotsClass), t = 0; t <= i.getDotCount(); t += 1) n.append(e("<li />").append(i.options.customPaging.call(this, i, t)));
            i.$dots = n.appendTo(i.options.appendDots), i.$dots.find("li").first().addClass("slick-active").attr("aria-hidden", "false")
        }
    }, t.prototype.buildOut = function() {
        var t = this;
        t.$slides = t.$slider.children(t.options.slide + ":not(.slick-cloned)").addClass("slick-slide"), t.slideCount = t.$slides.length, t.$slides.each(function(t, n) {
            e(n).attr("data-slick-index", t).data("originalStyling", e(n).attr("style") || "")
        }), t.$slider.addClass("slick-slider"), t.$slideTrack = 0 === t.slideCount ? e('<div class="slick-track"/>').appendTo(t.$slider) : t.$slides.wrapAll('<div class="slick-track"/>').parent(), t.$list = t.$slideTrack.wrap('<div aria-live="polite" class="slick-list"/>').parent(), t.$slideTrack.css("opacity", 0), t.options.swipeToSlide === !0 && (t.options.slidesToScroll = 1), e("img[data-lazy]", t.$slider).not("[src]").addClass("slick-loading"), t.setupInfinite(), t.buildArrows(), t.buildDots(), t.updateDots(), t.setSlideClasses("number" == typeof t.currentSlide ? t.currentSlide : 0), t.options.draggable === !0 && t.$list.addClass("draggable")
    }, t.prototype.buildRows = function() {
        var e, t, n, i, o, r, s, a = this;
        if (i = document.createDocumentFragment(), r = a.$slider.children(), a.options.rows > 1) {
            for (s = a.options.slidesPerRow * a.options.rows, o = Math.ceil(r.length / s), e = 0; e < o; e++) {
                var l = document.createElement("div");
                for (t = 0; t < a.options.rows; t++) {
                    var u = document.createElement("div");
                    for (n = 0; n < a.options.slidesPerRow; n++) {
                        var c = e * s + (t * a.options.slidesPerRow + n);
                        r.get(c) && u.appendChild(r.get(c))
                    }
                    l.appendChild(u)
                }
                i.appendChild(l)
            }
            a.$slider.empty().append(i), a.$slider.children().children().children().css({
                width: 100 / a.options.slidesPerRow + "%",
                display: "inline-block"
            })
        }
    }, t.prototype.changeSlide = function(t, n) {
        var i, o, r, s = this,
            a = e(t.currentTarget);
        switch (a.is("a") && t.preventDefault(), a.is("li") || (a = a.closest("li")), r = s.slideCount % s.options.slidesToScroll !== 0, i = r ? 0 : (s.slideCount - s.currentSlide) % s.options.slidesToScroll, t.data.message) {
            case "previous":
                o = 0 === i ? s.options.slidesToScroll : s.options.slidesToShow - i, s.slideCount > s.options.slidesToShow && s.slideHandler(s.currentSlide - o, !1, n);
                break;
            case "next":
                o = 0 === i ? s.options.slidesToScroll : i, s.slideCount > s.options.slidesToShow && s.slideHandler(s.currentSlide + o, !1, n);
                break;
            case "index":
                var l = 0 === t.data.index ? 0 : t.data.index || a.index() * s.options.slidesToScroll;
                s.slideHandler(s.checkNavigable(l), !1, n), a.children().trigger("focus");
                break;
            default:
                return
        }
    }, t.prototype.checkNavigable = function(e) {
        var t, n, i = this;
        if (t = i.getNavigableIndexes(), n = 0, e > t[t.length - 1]) e = t[t.length - 1];
        else
            for (var o in t) {
                if (e < t[o]) {
                    e = n;
                    break
                }
                n = t[o]
            }
        return e
    }, t.prototype.cleanUpEvents = function() {
        var t = this;
        t.options.dots && null !== t.$dots && e("li", t.$dots).off("click.slick", t.changeSlide).off("mouseenter.slick", e.proxy(t.interrupt, t, !0)).off("mouseleave.slick", e.proxy(t.interrupt, t, !1)), t.$slider.off("focus.slick blur.slick"), t.options.arrows === !0 && t.slideCount > t.options.slidesToShow && (t.$prevArrow && t.$prevArrow.off("click.slick", t.changeSlide), t.$nextArrow && t.$nextArrow.off("click.slick", t.changeSlide)), t.$list.off("touchstart.slick mousedown.slick", t.swipeHandler), t.$list.off("touchmove.slick mousemove.slick", t.swipeHandler), t.$list.off("touchend.slick mouseup.slick", t.swipeHandler), t.$list.off("touchcancel.slick mouseleave.slick", t.swipeHandler), t.$list.off("click.slick", t.clickHandler), e(document).off(t.visibilityChange, t.visibility), t.cleanUpSlideEvents(), t.options.accessibility === !0 && t.$list.off("keydown.slick", t.keyHandler), t.options.focusOnSelect === !0 && e(t.$slideTrack).children().off("click.slick", t.selectHandler), e(window).off("orientationchange.slick.slick-" + t.instanceUid, t.orientationChange), e(window).off("resize.slick.slick-" + t.instanceUid, t.resize), e("[draggable!=true]", t.$slideTrack).off("dragstart", t.preventDefault), e(window).off("load.slick.slick-" + t.instanceUid, t.setPosition), e(document).off("ready.slick.slick-" + t.instanceUid, t.setPosition)
    }, t.prototype.cleanUpSlideEvents = function() {
        var t = this;
        t.$list.off("mouseenter.slick", e.proxy(t.interrupt, t, !0)), t.$list.off("mouseleave.slick", e.proxy(t.interrupt, t, !1))
    }, t.prototype.cleanUpRows = function() {
        var e, t = this;
        t.options.rows > 1 && (e = t.$slides.children().children(), e.removeAttr("style"), t.$slider.empty().append(e))
    }, t.prototype.clickHandler = function(e) {
        var t = this;
        t.shouldClick === !1 && (e.stopImmediatePropagation(), e.stopPropagation(), e.preventDefault())
    }, t.prototype.destroy = function(t) {
        var n = this;
        n.autoPlayClear(), n.touchObject = {}, n.cleanUpEvents(), e(".slick-cloned", n.$slider).detach(), n.$dots && n.$dots.remove(), n.$prevArrow && n.$prevArrow.length && (n.$prevArrow.removeClass("slick-disabled slick-arrow slick-hidden").removeAttr("aria-hidden aria-disabled tabindex").css("display", ""), n.htmlExpr.test(n.options.prevArrow) && n.$prevArrow.remove()), n.$nextArrow && n.$nextArrow.length && (n.$nextArrow.removeClass("slick-disabled slick-arrow slick-hidden").removeAttr("aria-hidden aria-disabled tabindex").css("display", ""), n.htmlExpr.test(n.options.nextArrow) && n.$nextArrow.remove()), n.$slides && (n.$slides.removeClass("slick-slide slick-active slick-center slick-visible slick-current").removeAttr("aria-hidden").removeAttr("data-slick-index").each(function() {
            e(this).attr("style", e(this).data("originalStyling"))
        }), n.$slideTrack.children(this.options.slide).detach(), n.$slideTrack.detach(), n.$list.detach(), n.$slider.append(n.$slides)), n.cleanUpRows(), n.$slider.removeClass("slick-slider"), n.$slider.removeClass("slick-initialized"), n.$slider.removeClass("slick-dotted"), n.unslicked = !0, t || n.$slider.trigger("destroy", [n])
    }, t.prototype.disableTransition = function(e) {
        var t = this,
            n = {};
        n[t.transitionType] = "", t.options.fade === !1 ? t.$slideTrack.css(n) : t.$slides.eq(e).css(n)
    }, t.prototype.fadeSlide = function(e, t) {
        var n = this;
        n.cssTransitions === !1 ? (n.$slides.eq(e).css({
            zIndex: n.options.zIndex
        }), n.$slides.eq(e).animate({
            opacity: 1
        }, n.options.speed, n.options.easing, t)) : (n.applyTransition(e), n.$slides.eq(e).css({
            opacity: 1,
            zIndex: n.options.zIndex
        }), t && setTimeout(function() {
            n.disableTransition(e), t.call()
        }, n.options.speed))
    }, t.prototype.fadeSlideOut = function(e) {
        var t = this;
        t.cssTransitions === !1 ? t.$slides.eq(e).animate({
            opacity: 0,
            zIndex: t.options.zIndex - 2
        }, t.options.speed, t.options.easing) : (t.applyTransition(e), t.$slides.eq(e).css({
            opacity: 0,
            zIndex: t.options.zIndex - 2
        }))
    }, t.prototype.filterSlides = t.prototype.slickFilter = function(e) {
        var t = this;
        null !== e && (t.$slidesCache = t.$slides, t.unload(), t.$slideTrack.children(this.options.slide).detach(), t.$slidesCache.filter(e).appendTo(t.$slideTrack), t.reinit())
    }, t.prototype.focusHandler = function() {
        var t = this;
        t.$slider.off("focus.slick blur.slick").on("focus.slick blur.slick", "*:not(.slick-arrow)", function(n) {
            n.stopImmediatePropagation();
            var i = e(this);
            setTimeout(function() {
                t.options.pauseOnFocus && (t.focussed = i.is(":focus"), t.autoPlay())
            }, 0)
        })
    }, t.prototype.getCurrent = t.prototype.slickCurrentSlide = function() {
        var e = this;
        return e.currentSlide
    }, t.prototype.getDotCount = function() {
        for (var e = this, t = 0, n = 0, i = 0; t < e.slideCount;) ++i, t = n + e.options.slidesToScroll, n += e.options.slidesToScroll <= e.options.slidesToShow ? e.options.slidesToScroll : e.options.slidesToShow;
        return i - 1
    }, t.prototype.getLeft = function(e) {
        var t, n, i = this,
            o = 0;
        return i.slideOffset = 0, n = i.$slides.first().outerHeight(!0), i.slideCount > i.options.slidesToShow && (i.slideOffset = i.slideWidth * i.options.slidesToShow * -1, o = n * i.options.slidesToShow * -1), i.slideCount % i.options.slidesToScroll !== 0 && e + i.options.slidesToScroll > i.slideCount && i.slideCount > i.options.slidesToShow && (e > i.slideCount ? (i.slideOffset = (i.options.slidesToShow - (e - i.slideCount)) * i.slideWidth * -1, o = (i.options.slidesToShow - (e - i.slideCount)) * n * -1) : (i.slideOffset = i.slideCount % i.options.slidesToScroll * i.slideWidth * -1, o = i.slideCount % i.options.slidesToScroll * n * -1)), i.slideCount <= i.options.slidesToShow && (i.slideOffset = 0, o = 0), t = e * i.slideWidth * -1 + i.slideOffset
    }, t.prototype.getOption = t.prototype.slickGetOption = function(e) {
        var t = this;
        return t.options[e]
    }, t.prototype.getNavigableIndexes = function() {
        var e, t = this,
            n = 0,
            i = 0,
            o = [];
        for (n = t.options.slidesToScroll * -1, i = t.options.slidesToScroll * -1, e = 2 * t.slideCount; n < e;) o.push(n), n = i + t.options.slidesToScroll, i += t.options.slidesToScroll <= t.options.slidesToShow ? t.options.slidesToScroll : t.options.slidesToShow;
        return o
    }, t.prototype.getSlick = function() {
        return this
    }, t.prototype.getSlideCount = function() {
        var t, n, i, o = this;
        return i = 0, o.options.swipeToSlide === !0 ? (o.$slideTrack.find(".slick-slide").each(function(t, r) {
            if (r.offsetLeft - i + e(r).outerWidth() / 2 > o.swipeLeft * -1) return n = r, !1
        }), t = Math.abs(e(n).attr("data-slick-index") - o.currentSlide) || 1) : o.options.slidesToScroll
    }, t.prototype.goTo = t.prototype.slickGoTo = function(e, t) {
        var n = this;
        n.changeSlide({
            data: {
                message: "index",
                index: parseInt(e)
            }
        }, t)
    }, t.prototype.init = function(t) {
        var n = this;
        e(n.$slider).hasClass("slick-initialized") || (e(n.$slider).addClass("slick-initialized"), n.buildRows(), n.buildOut(), n.setProps(), n.startLoad(), n.loadSlider(), n.initializeEvents(), n.updateDots(), n.focusHandler()), t && n.$slider.trigger("init", [n]), n.options.accessibility === !0 && n.initADA(), n.options.autoplay && (n.paused = !1, n.autoPlay())
    }, t.prototype.initADA = function() {
        var t, n, i, o = this;
        o.$slides.not(o.$slideTrack.find(".slick-cloned")).each(function(r) {
            e(this).attr("role", "option");
            var s = Math.floor(r / o.options.slidesToShow);
            o.options.dots === !0 && (n = "" + o.instanceUid + s, i = n, t === n && (n = "" + n + r), e(this).attr("id", "slickSlide" + n).attr("role", "tabpanel").attr("aria-labelledby", "slickDot" + i), t = "" + o.instanceUid + s)
        }), null !== o.$dots && o.$dots.attr("role", "tablist").find("li").each(function(t) {
            e(this).attr({
                role: "tab",
                "aria-selected": "false",
                "aria-controls": "slickSlide" + o.instanceUid + t,
                id: "slickDot" + o.instanceUid + t
            })
        }).first().attr("aria-selected", "true"), o.activateADA()
    }, t.prototype.initArrowEvents = function() {
        var e = this;
        e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.off("click.slick").on("click.slick", {
            message: "previous"
        }, e.changeSlide), e.$nextArrow.off("click.slick").on("click.slick", {
            message: "next"
        }, e.changeSlide))
    }, t.prototype.updateADA = function() {
        var e = this;
        null !== e.$dots && (e.$dots.find("li").attr("aria-selected", "false"), e.$dots.find(".slick-active").attr("aria-selected", "true")), e.activateADA()
    }, t.prototype.initArrowEvents = function() {
        var e = this;
        e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.off("click.slick").on("click.slick", {
            message: "previous"
        }, e.changeSlide), e.$nextArrow.off("click.slick").on("click.slick", {
            message: "next"
        }, e.changeSlide))
    }, t.prototype.initDotEvents = function() {
        var t = this;
        t.options.dots === !0 && t.slideCount > t.options.slidesToShow && e("li", t.$dots).on("click.slick", {
            message: "index"
        }, t.changeSlide), t.options.dots === !0 && t.options.pauseOnDotsHover === !0 && e("li", t.$dots).on("mouseenter.slick", e.proxy(t.interrupt, t, !0)).on("mouseleave.slick", e.proxy(t.interrupt, t, !1))
    }, t.prototype.initSlideEvents = function() {
        var t = this;
        t.options.pauseOnHover && (t.$list.on("mouseenter.slick", e.proxy(t.interrupt, t, !0)), t.$list.on("mouseleave.slick", e.proxy(t.interrupt, t, !1)))
    }, t.prototype.initializeEvents = function() {
        var t = this;
        t.initArrowEvents(), t.initDotEvents(), t.initSlideEvents(), t.$list.on("touchstart.slick mousedown.slick", {
            action: "start"
        }, t.swipeHandler), t.$list.on("touchmove.slick mousemove.slick", {
            action: "move"
        }, t.swipeHandler), t.$list.on("touchend.slick mouseup.slick", {
            action: "end"
        }, t.swipeHandler), t.$list.on("touchcancel.slick mouseleave.slick", {
            action: "end"
        }, t.swipeHandler), t.$list.on("click.slick", t.clickHandler), e(document).on(t.visibilityChange, e.proxy(t.visibility, t)), t.options.accessibility === !0 && t.$list.on("keydown.slick", t.keyHandler), t.options.focusOnSelect === !0 && e(t.$slideTrack).children().on("click.slick", t.selectHandler), e(window).on("orientationchange.slick.slick-" + t.instanceUid, e.proxy(t.orientationChange, t)), e(window).on("resize.slick.slick-" + t.instanceUid, e.proxy(t.resize, t)), e("[draggable!=true]", t.$slideTrack).on("dragstart", t.preventDefault), e(window).on("load.slick.slick-" + t.instanceUid, t.setPosition), e(document).on("ready.slick.slick-" + t.instanceUid, t.setPosition)
    }, t.prototype.initUI = function() {
        var e = this;
        e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.show(), e.$nextArrow.show()), e.options.dots === !0 && e.slideCount > e.options.slidesToShow && e.$dots.show()
    }, t.prototype.keyHandler = function(e) {
        var t = this;
        e.target.tagName.match("TEXTAREA|INPUT|SELECT") || (37 === e.keyCode && t.options.accessibility === !0 ? t.changeSlide({
            data: {
                message: t.options.rtl === !0 ? "next" : "previous"
            }
        }) : 39 === e.keyCode && t.options.accessibility === !0 && t.changeSlide({
            data: {
                message: t.options.rtl === !0 ? "previous" : "next"
            }
        }))
    }, t.prototype.lazyLoad = function() {
        function t(t) {
            e("img[data-lazy]", t).each(function() {
                var t = e(this),
                    n = e(this).attr("data-lazy"),
                    i = document.createElement("img");
                i.onload = function() {
                    t.animate({
                        opacity: 0
                    }, 100, function() {
                        t.attr("src", n).animate({
                            opacity: 1
                        }, 200, function() {
                            t.removeAttr("data-lazy").removeClass("slick-loading")
                        }), s.$slider.trigger("lazyLoaded", [s, t, n])
                    })
                }, i.onerror = function() {
                    t.removeAttr("data-lazy").removeClass("slick-loading").addClass("slick-lazyload-error"), s.$slider.trigger("lazyLoadError", [s, t, n])
                }, i.src = n
            })
        }
        var n, i, o, r, s = this;
        o = s.options.slidesToShow, r = Math.ceil(o + s.options.slidesToShow), s.options.fade === !0 && (o > 0 && o--, r <= s.slideCount && r++), n = s.$slider.find(".slick-slide").slice(o, r), t(n), s.slideCount <= s.options.slidesToShow ? (i = s.$slider.find(".slick-slide"), t(i)) : s.currentSlide >= s.slideCount - s.options.slidesToShow ? (i = s.$slider.find(".slick-cloned").slice(0, s.options.slidesToShow), t(i)) : 0 === s.currentSlide && (i = s.$slider.find(".slick-cloned").slice(s.options.slidesToShow * -1), t(i))
    }, t.prototype.loadSlider = function() {
        var e = this;
        e.setPosition(), e.$slideTrack.css({
            opacity: 1
        }), e.$slider.removeClass("slick-loading"), e.initUI()
    }, t.prototype.next = t.prototype.slickNext = function() {
        var e = this;
        e.changeSlide({
            data: {
                message: "next"
            }
        })
    }, t.prototype.orientationChange = function() {
        var e = this;
        e.setPosition()
    }, t.prototype.pause = t.prototype.slickPause = function() {
        var e = this;
        e.autoPlayClear(), e.paused = !0
    }, t.prototype.play = t.prototype.slickPlay = function() {
        var e = this;
        e.autoPlay(), e.options.autoplay = !0, e.paused = !1, e.focussed = !1, e.interrupted = !1
    }, t.prototype.postSlide = function(e) {
        var t = this;
        t.unslicked || (t.$slider.trigger("afterChange", [t, e]), t.animating = !1, t.setPosition(), t.swipeLeft = null, t.options.autoplay && t.autoPlay(), t.options.accessibility === !0 && t.updateADA())
    }, t.prototype.prev = t.prototype.slickPrev = function() {
        var e = this;
        e.changeSlide({
            data: {
                message: "previous"
            }
        })
    }, t.prototype.preventDefault = function(e) {
        e.preventDefault()
    }, t.prototype.refresh = function(t) {
        var n, i, o = this;
        i = o.slideCount - o.options.slidesToShow, o.slideCount <= o.options.slidesToShow && (o.currentSlide = 0), n = o.currentSlide, o.destroy(!0), e.extend(o, o.initials, {
            currentSlide: n
        }), o.init(), t || o.changeSlide({
            data: {
                message: "index",
                index: n
            }
        }, !1)
    }, t.prototype.reinit = function() {
        var t = this;
        t.$slides = t.$slideTrack.children(t.options.slide).addClass("slick-slide"), t.slideCount = t.$slides.length, t.currentSlide >= t.slideCount && 0 !== t.currentSlide && (t.currentSlide = t.currentSlide - t.options.slidesToScroll), t.slideCount <= t.options.slidesToShow && (t.currentSlide = 0), t.setProps(), t.setupInfinite(), t.buildArrows(), t.initArrowEvents(), t.buildDots(), t.updateDots(), t.initDotEvents(), t.cleanUpSlideEvents(), t.initSlideEvents(), t.options.focusOnSelect === !0 && e(t.$slideTrack).children().on("click.slick", t.selectHandler), t.setSlideClasses("number" == typeof t.currentSlide ? t.currentSlide : 0), t.setPosition(), t.focusHandler(), t.paused = !t.options.autoplay, t.autoPlay(), t.$slider.trigger("reInit", [t])
    }, t.prototype.resize = function() {
        var t = this;
        e(window).width() !== t.windowWidth && (clearTimeout(t.windowDelay), t.windowDelay = window.setTimeout(function() {
            t.windowWidth = e(window).width(), t.unslicked || t.setPosition()
        }, 50))
    }, t.prototype.setCSS = function(e) {
        var t, n, i = this,
            o = {};
        i.options.rtl === !0 && (e = -e), t = "left" == i.positionProp ? Math.ceil(e) + "px" : "0px", n = "top" == i.positionProp ? Math.ceil(e) + "px" : "0px", o[i.positionProp] = e, i.transformsEnabled === !1 ? i.$slideTrack.css(o) : (o = {}, i.cssTransitions === !1 ? (o[i.animType] = "translate(" + t + ", " + n + ")", i.$slideTrack.css(o)) : (o[i.animType] = "translate3d(" + t + ", " + n + ", 0px)", i.$slideTrack.css(o)))
    }, t.prototype.setDimensions = function() {
        var e = this;
        e.listWidth = e.$list.width(), e.listHeight = e.$list.height(), e.slideWidth = Math.ceil(e.listWidth / e.options.slidesToShow), e.$slideTrack.width(Math.ceil(e.slideWidth * e.$slideTrack.children(".slick-slide").length));
        var t = e.$slides.first().outerWidth(!0) - e.$slides.first().width();
        e.$slideTrack.children(".slick-slide").width(e.slideWidth - t)
    }, t.prototype.setFade = function() {
        var t, n = this;
        n.$slides.each(function(i, o) {
            t = n.slideWidth * i * -1, n.options.rtl === !0 ? e(o).css({
                position: "relative",
                right: t,
                top: 0,
                zIndex: n.options.zIndex - 2,
                opacity: 0
            }) : e(o).css({
                position: "relative",
                left: t,
                top: 0,
                zIndex: n.options.zIndex - 2,
                opacity: 0
            })
        }), n.$slides.eq(n.currentSlide).css({
            zIndex: n.options.zIndex - 1,
            opacity: 1
        })
    }, t.prototype.setOption = t.prototype.slickSetOption = function() {
        var t, n, i, o = this,
            r = !1;
        "object" === e.type(arguments[0]) ? (t = arguments[0], r = arguments[1], i = "multiple") : "string" === e.type(arguments[0]) && (t = arguments[0], n = arguments[1], r = arguments[2], "undefined" != typeof arguments[1] && (i = "single")), "single" === i ? o.options[t] = n : "multiple" === i && e.each(t, function(e, t) {
            o.options[e] = t
        }), r && (o.unload(), o.reinit())
    }, t.prototype.setPosition = function() {
        var e = this;
        e.setDimensions(), e.options.fade === !1 ? e.setCSS(e.getLeft(e.currentSlide)) : e.setFade(), e.$slider.trigger("setPosition", [e])
    }, t.prototype.setProps = function() {
        var e = this,
            t = document.body.style;
        e.positionProp = "left", e.$slider.removeClass("slick-vertical"), void 0 === t.WebkitTransition && void 0 === t.MozTransition && void 0 === t.msTransition || e.options.useCSS === !0 && (e.cssTransitions = !0), e.options.fade && ("number" == typeof e.options.zIndex ? e.options.zIndex < 3 && (e.options.zIndex = 3) : e.options.zIndex = e.defaults.zIndex), void 0 !== t.OTransform && (e.animType = "OTransform", e.transformType = "-o-transform", e.transitionType = "OTransition", void 0 === t.perspectiveProperty && void 0 === t.webkitPerspective && (e.animType = !1)), void 0 !== t.MozTransform && (e.animType = "MozTransform", e.transformType = "-moz-transform", e.transitionType = "MozTransition", void 0 === t.perspectiveProperty && void 0 === t.MozPerspective && (e.animType = !1)), void 0 !== t.webkitTransform && (e.animType = "webkitTransform", e.transformType = "-webkit-transform", e.transitionType = "webkitTransition", void 0 === t.perspectiveProperty && void 0 === t.webkitPerspective && (e.animType = !1)), void 0 !== t.msTransform && (e.animType = "msTransform", e.transformType = "-ms-transform", e.transitionType = "msTransition", void 0 === t.msTransform && (e.animType = !1)), void 0 !== t.transform && e.animType !== !1 && (e.animType = "transform", e.transformType = "transform", e.transitionType = "transition"), e.transformsEnabled = e.options.useTransform && null !== e.animType && e.animType !== !1
    }, t.prototype.setSlideClasses = function(e) {
        var t, n, i, o = this;
        t = o.$slider.find(".slick-slide").removeClass("slick-active slick-center slick-current").attr("aria-hidden", "true"), o.$slides.eq(e).addClass("slick-current"), e >= 0 && e <= o.slideCount - o.options.slidesToShow ? o.$slides.slice(e, e + o.options.slidesToShow).addClass("slick-active").attr("aria-hidden", "false") : t.length <= o.options.slidesToShow ? t.addClass("slick-active").attr("aria-hidden", "false") : (i = o.slideCount % o.options.slidesToShow, n = o.options.slidesToShow + e, o.options.slidesToShow == o.options.slidesToScroll && o.slideCount - e < o.options.slidesToShow ? t.slice(n - (o.options.slidesToShow - i), n + i).addClass("slick-active").attr("aria-hidden", "false") : t.slice(n, n + o.options.slidesToShow).addClass("slick-active").attr("aria-hidden", "false")), o.options.lazyLoad === !0 && o.lazyLoad()
    }, t.prototype.setupInfinite = function() {
        var t, n, i, o = this;
        if (o.options.fade === !1 && (n = null, o.slideCount > o.options.slidesToShow)) {
            for (i = o.options.slidesToShow, t = o.slideCount; t > o.slideCount - i; t -= 1) n = t - 1, e(o.$slides[n]).clone(!0).attr("id", "").attr("data-slick-index", n - o.slideCount).prependTo(o.$slideTrack).addClass("slick-cloned");
            for (t = 0; t < i; t += 1) n = t, e(o.$slides[n]).clone(!0).attr("id", "").attr("data-slick-index", n + o.slideCount).appendTo(o.$slideTrack).addClass("slick-cloned");
            o.$slideTrack.find(".slick-cloned").find("[id]").each(function() {
                e(this).attr("id", "")
            })
        }
    }, t.prototype.interrupt = function(e) {
        var t = this;
        e || t.autoPlay(), t.interrupted = e
    }, t.prototype.selectHandler = function(t) {
        var n = this,
            i = e(t.target).is(".slick-slide") ? e(t.target) : e(t.target).parents(".slick-slide"),
            o = parseInt(i.attr("data-slick-index"));
        return o || (o = 0), n.slideCount <= n.options.slidesToShow ? (n.setSlideClasses(o), void n.asNavFor(o)) : void n.slideHandler(o)
    }, t.prototype.slideHandler = function(e, t, n) {
        var i, o, r, s, a, l = null,
            u = this;
        if (t = t || !1, (u.animating !== !0 || u.options.waitForAnimate !== !0) && !(u.options.fade === !0 && u.currentSlide === e || u.slideCount <= u.options.slidesToShow)) return t === !1 && u.asNavFor(e), i = e, l = u.getLeft(i), s = u.getLeft(u.currentSlide), u.currentLeft = null === u.swipeLeft ? s : u.swipeLeft, u.options.autoplay && clearInterval(u.autoPlayTimer), o = i < 0 ? u.slideCount % u.options.slidesToScroll !== 0 ? u.slideCount - u.slideCount % u.options.slidesToScroll : u.slideCount + i : i >= u.slideCount ? u.slideCount % u.options.slidesToScroll !== 0 ? 0 : i - u.slideCount : i, u.animating = !0, u.$slider.trigger("beforeChange", [u, u.currentSlide, o]), r = u.currentSlide, u.currentSlide = o, u.setSlideClasses(u.currentSlide), u.options.asNavFor && (a = u.getNavTarget(), a = a.slick("getSlick"), a.slideCount <= a.options.slidesToShow && a.setSlideClasses(u.currentSlide)), u.updateDots(), u.options.fade === !0 ? void(n !== !0 ? (u.fadeSlideOut(r), u.fadeSlide(o, function() {
            u.postSlide(o)
        })) : u.postSlide(o)) : void(n !== !0 ? u.animateSlide(l, function() {
            u.postSlide(o)
        }) : u.postSlide(o))
    }, t.prototype.startLoad = function() {
        var e = this;
        e.options.arrows === !0 && e.slideCount > e.options.slidesToShow && (e.$prevArrow.hide(), e.$nextArrow.hide()), e.options.dots === !0 && e.slideCount > e.options.slidesToShow && e.$dots.hide(), e.$slider.addClass("slick-loading")
    }, t.prototype.swipeDirection = function() {
        var e, t, n, i, o = this;
        return e = o.touchObject.startX - o.touchObject.curX, t = o.touchObject.startY - o.touchObject.curY, n = Math.atan2(t, e), i = Math.round(180 * n / Math.PI), i < 0 && (i = 360 - Math.abs(i)), i <= 45 && i >= 0 ? o.options.rtl === !1 ? "left" : "right" : i <= 360 && i >= 315 ? o.options.rtl === !1 ? "left" : "right" : i >= 135 && i <= 225 ? o.options.rtl === !1 ? "right" : "left" : "vertical"
    }, t.prototype.swipeEnd = function(e) {
        var t, n, i = this;
        if (i.dragging = !1, i.interrupted = !1, i.shouldClick = !(i.touchObject.swipeLength > 10), void 0 === i.touchObject.curX) return !1;
        if (i.touchObject.edgeHit === !0 && i.$slider.trigger("edge", [i, i.swipeDirection()]), i.touchObject.swipeLength >= i.touchObject.minSwipe) {
            switch (n = i.swipeDirection()) {
                case "left":
                case "down":
                    t = i.options.swipeToSlide ? i.checkNavigable(i.currentSlide + i.getSlideCount()) : i.currentSlide + i.getSlideCount(), i.currentDirection = 0;
                    break;
                case "right":
                case "up":
                    t = i.options.swipeToSlide ? i.checkNavigable(i.currentSlide - i.getSlideCount()) : i.currentSlide - i.getSlideCount(), i.currentDirection = 1
            }
            "vertical" != n && (i.slideHandler(t), i.touchObject = {}, i.$slider.trigger("swipe", [i, n]))
        } else i.touchObject.startX !== i.touchObject.curX && (i.slideHandler(i.currentSlide), i.touchObject = {})
    }, t.prototype.swipeHandler = function(e) {
        var t = this;
        if (!(t.options.swipe === !1 || "ontouchend" in document && t.options.swipe === !1 || t.options.draggable === !1 && e.type.indexOf("mouse") !== -1)) switch (t.touchObject.fingerCount = e.originalEvent && void 0 !== e.originalEvent.touches ? e.originalEvent.touches.length : 1, t.touchObject.minSwipe = t.listWidth / t.options.touchThreshold, e.data.action) {
            case "start":
                t.swipeStart(e);
                break;
            case "move":
                t.swipeMove(e);
                break;
            case "end":
                t.swipeEnd(e)
        }
    }, t.prototype.swipeMove = function(e) {
        var t, n, i, o, r, s = this;
        return r = void 0 !== e.originalEvent ? e.originalEvent.touches : null, !(!s.dragging || r && 1 !== r.length) && (t = s.getLeft(s.currentSlide), s.touchObject.curX = void 0 !== r ? r[0].pageX : e.clientX, s.touchObject.curY = void 0 !== r ? r[0].pageY : e.clientY, s.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(s.touchObject.curX - s.touchObject.startX, 2))), n = s.swipeDirection(), "vertical" !== n ? (void 0 !== e.originalEvent && s.touchObject.swipeLength > 4 && e.preventDefault(), o = (s.options.rtl === !1 ? 1 : -1) * (s.touchObject.curX > s.touchObject.startX ? 1 : -1), s.options.verticalSwiping === !0 && (o = s.touchObject.curY > s.touchObject.startY ? 1 : -1), i = s.touchObject.swipeLength, s.touchObject.edgeHit = !1, s.swipeLeft = t + i * o, s.options.fade !== !0 && s.options.touchMove !== !1 && (s.animating === !0 ? (s.swipeLeft = null, !1) : void s.setCSS(s.swipeLeft))) : void 0)
    }, t.prototype.swipeStart = function(e) {
        var t, n = this;
        return n.interrupted = !0, 1 !== n.touchObject.fingerCount || n.slideCount <= n.options.slidesToShow ? (n.touchObject = {}, !1) : (void 0 !== e.originalEvent && void 0 !== e.originalEvent.touches && (t = e.originalEvent.touches[0]), n.touchObject.startX = n.touchObject.curX = void 0 !== t ? t.pageX : e.clientX, n.touchObject.startY = n.touchObject.curY = void 0 !== t ? t.pageY : e.clientY, void(n.dragging = !0))
    }, t.prototype.unfilterSlides = t.prototype.slickUnfilter = function() {
        var e = this;
        null !== e.$slidesCache && (e.unload(), e.$slideTrack.children(this.options.slide).detach(), e.$slidesCache.appendTo(e.$slideTrack), e.reinit())
    }, t.prototype.unload = function() {
        var t = this;
        e(".slick-cloned", t.$slider).remove(), t.$dots && t.$dots.remove(), t.$prevArrow && t.htmlExpr.test(t.options.prevArrow) && t.$prevArrow.remove(), t.$nextArrow && t.htmlExpr.test(t.options.nextArrow) && t.$nextArrow.remove(), t.$slides.removeClass("slick-slide slick-active slick-visible slick-current").attr("aria-hidden", "true").css("width", "")
    }, t.prototype.unslick = function(e) {
        var t = this;
        t.$slider.trigger("unslick", [t, e]), t.destroy()
    }, t.prototype.updateDots = function() {
        var e = this;
        null !== e.$dots && (e.$dots.find("li").removeClass("slick-active"), e.$dots.find("li").eq(Math.floor(e.currentSlide / e.options.slidesToScroll)).addClass("slick-active"))
    }, t.prototype.visibility = function() {
        var e = this;
        e.options.autoplay && (document[e.hidden] ? e.interrupted = !0 : e.interrupted = !1)
    }, e.fn.slick = function() {
        var e, n, i = this,
            o = arguments[0],
            r = Array.prototype.slice.call(arguments, 1),
            s = i.length;
        for (e = 0; e < s; e++)
            if ("object" == typeof o || "undefined" == typeof o ? i[e].slick = new t(i[e], o) : n = i[e].slick[o].apply(i[e].slick, r), "undefined" != typeof n) return n;
        return i
    }
});

var quotes = (function() {
  var config = {
    mediaQuerySmall: 'screen and (max-width: 749px)',
    mediaQueryMediumUp: 'screen and (min-width: 750px)',
    slideCount: 0
  };
  var defaults = {
    accessibility: true,
    arrows: false,
    dots: true,
    autoplay: false,
    touchThreshold: 20,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  function Quotes(container) {
    var $container = this.$container = $(container);
    var sectionId = $container.attr('data-section-id');
    var wrapper = this.wrapper = '.quotes-wrapper';
    var slider = this.slider = '#Quotes-' + sectionId;
    var $slider = $(slider, wrapper);

    var sliderActive = false;
    var mobileOptions = $.extend({}, defaults, {
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    });

    config.slideCount = $slider.data('count');

    // Override slidesToShow/Scroll if there are not enough blocks
    if (config.slideCount < defaults.slidesToShow) {
      defaults.slidesToShow = config.slideCount;
      defaults.slidesToScroll = config.slideCount;
    }

    $slider.on('init', this.a11y.bind(this));

    enquire.register(config.mediaQuerySmall, {
      match: function() {
        initSlider($slider, mobileOptions);
      }
    });

    enquire.register(config.mediaQueryMediumUp, {
      match: function() {
        initSlider($slider, defaults);
      }
    });

    function initSlider(sliderObj, args) {
      if (sliderActive) {
        sliderObj.slick('unslick');
        sliderActive = false;
      }

      sliderObj.slick(args);
      sliderActive = true;
    }
  }

  Quotes.prototype = _.assignIn({}, Quotes.prototype, {
    onUnload: function() {
      enquire.unregister(config.mediaQuerySmall);
      enquire.unregister(config.mediaQueryMediumUp);

      $(this.slider, this.wrapper).slick('unslick');
    },

    onBlockSelect: function(evt) {
      // Ignore the cloned version
      var $slide = $('.quotes-slide--' + evt.detail.blockId + ':not(.slick-cloned)');
      var slideIndex = $slide.data('slick-index');

      // Go to selected slide, pause autoplay
      $(this.slider, this.wrapper).slick('slickGoTo', slideIndex);
    },

    a11y: function(event, obj) {
      var $list = obj.$list;
      var $wrapper = $(this.wrapper, this.$container);

      // Remove default Slick aria-live attr until slider is focused
      $list.removeAttr('aria-live');

      // When an element in the slider is focused set aria-live
      $wrapper.on('focusin', function(evt) {
        if ($wrapper.has(evt.target).length) {
          $list.attr('aria-live', 'polite');
        }
      });

      // Remove aria-live
      $wrapper.on('focusout', function(evt) {
        if ($wrapper.has(evt.target).length) {
          $list.removeAttr('aria-live');
        }
      });
    }
  });

  return Quotes;
})();

new quotes($('[data-section-type="quotes"]'));

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /* global window */
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Gallery = function () {
  function Gallery(slider) {
    var _this = this;

    _classCallCheck(this, Gallery);

    this.$slider = $(slider);
    this.$photos = this.$slider.children('.photos');
    this.$thumbs = this.$slider.children('.thumbs');
    this.$controls = this.$slider.children('.controls');
    this.$controlsPrev = this.$controls.children('.prev');
    this.$controlsNext = this.$controls.children('.next');
    this.auto = false;
    this.autoInterval = 0;
    this.slides = [];
    this.slide = 0;

    this.$controlsPrev.on('click', function () {
      _this.prev();
      return false;
    });

    this.$controlsNext.on('click', function () {
      _this.next();
      return false;
    });

    this.$thumbs.on('click', '.thumb', function (event) {
      var slide = $(event.currentTarget).data('slide');
      _this.setSlideAsActive(slide);
      return false;
    });

    var $photos = this.getPhotos();
    var $thumbs = this.getThumbs();

    if ($photos.length !== $thumbs.length) {
      throw new Error();
    }

    if (!$photos.length) {
      throw new Error();
    }

    $photos.each(function (slide, el) {
      var $slide = $(el);
      var $thumb = $thumbs.eq(slide).data('slide', slide);
      _this.slides[slide] = {
        photo: $slide,
        thumb: $thumb
      };
      if ($slide.hasClass('active')) {
        _this.slide = slide;
      }
    });

    if (!$photos.filter('.active').length) {
      this.disableAnimation();
      this.setSlideAsActive(this.slide);
      window.setTimeout(function () {
        return _this.enableAnimation();
      }, 100);
    }

    if (this.slides.length > 1) {
      this.$slider.addClass('active');
      this.$controls.addClass('active');
    }
  }

  _createClass(Gallery, [{
    key: 'getPhotos',
    value: function getPhotos() {
      return this.$photos.children();
    }
  }, {
    key: 'getThumbs',
    value: function getThumbs() {
      return this.$thumbs.children();
    }
  }, {
    key: 'prev',
    value: function prev() {
      this.changeSlide(this.getPrevSlide());
      return this;
    }
  }, {
    key: 'next',
    value: function next() {
      this.changeSlide(this.getNextSlide());
      return this;
    }
  }, {
    key: 'changeSlide',
    value: function changeSlide(slide) {
      if (this.auto) this.stopAuto();
      this.setSlideAsActive(slide);
      if (this.auto) this.startAuto();
    }
  }, {
    key: 'start',
    value: function start(interval) {
      this.setAuto(interval);
      return this;
    }
  }, {
    key: 'stop',
    value: function stop() {
      this.setAuto(0);
      return this;
    }
  }, {
    key: 'animate',
    value: function animate(animation) {
      if (animation) {
        this.enableAnimation();
      } else {
        this.disableAnimation();
      }
      return this;
    }
  }, {
    key: 'setSlideAsActive',
    value: function setSlideAsActive(slide) {
      this.slide = slide;
      var slideObj = this.slides[this.slide];
      var $photo = slideObj.photo.addClass('active');
      var $thumb = slideObj.thumb;
      this.setThumbAsActive(slide, $thumb);
      $photo.siblings().removeClass('active');
    }
  }, {
    key: 'setThumbAsActive',
    value: function setThumbAsActive(slide, $thumb) {
      var $thumbs = this.getThumbs();
      $thumbs.removeClass('active');
      $thumb.addClass('active');
      if ($thumbs.length < 5) {
        return;
      }
      var curIndex = $thumb.index();
      while (curIndex !== 2) {
        if (slide.length - slide >= slide) {
          $thumbs.filter(':first-child').before($thumbs.filter(':last-child'));
        } else {
          $thumbs.filter(':last-child').after($thumbs.filter(':first-child'));
        }
        curIndex = $thumb.index();
      }
    }
  }, {
    key: 'getNextSlide',
    value: function getNextSlide() {
      if (this.slide >= this.slides.length - 1) {
        return 0;
      }
      return this.slide + 1;
    }
  }, {
    key: 'getPrevSlide',
    value: function getPrevSlide() {
      if (this.slide <= 0) {
        return this.slides.length - 1;
      }
      return this.slide - 1;
    }
  }, {
    key: 'enableAnimation',
    value: function enableAnimation() {
      this.$slider.addClass('animate');
    }
  }, {
    key: 'disableAnimation',
    value: function disableAnimation() {
      this.$slider.removeClass('animate');
    }
  }, {
    key: 'setAuto',
    value: function setAuto(interval) {
      if (interval > 0) {
        this.autoInterval = interval;
        this.startAuto();
      } else {
        this.autoInterval = 0;
        this.stopAuto();
      }
    }
  }, {
    key: 'startAuto',
    value: function startAuto() {
      var _this2 = this;

      this.auto = window.setInterval(function () {
        return _this2.setSlideAsActive(_this2.getNextSlide());
      }, this.autoInterval);
    }
  }, {
    key: 'stopAuto',
    value: function stopAuto() {
      if (this.auto) {
        window.clearInterval(this.auto);
      }
    }
  }]);

  return Gallery;
}();

function createGallery(el) {
  var $postGallery = $(el);
  var $title = $postGallery.children('.title');
  var title = $title.length ? $title.text() : null;
  var $images = $postGallery.children('img');
  var $Gallery = $('<div class="Gallery" ></div>');
  var $photos = $('<ul class="photos" ></ul>').appendTo($Gallery);
  $('<img src="//cdn.shopify.com/s/files/1/1614/0047/t/24/assets/ratio_1x1.png?9681200881488189172">').appendTo($Gallery);
  var $controls = $('<div class="controls" ></div>').appendTo($Gallery);
  var $thumbs = $('<ul class="thumbs" ></ul>').appendTo($Gallery);
  $('<div class="prev" ><span class="icon"></span></div>').appendTo($controls);
  $('<div class="next" ><span class="icon"></span></div>').appendTo($controls);
  $images.each(function(idx, el) {
    var $img = $(el);
    var $photo = $('<li class="photo" ></li>').appendTo($photos);
    var $photoA = $('<a href="' + $img.prop('src') + '" data-fancybox="swipebox" rel="gallery" ></a>').appendTo($photo);
    var $photoImage = $('<img src="' + $img.prop('src') + '" />').appendTo($photoA);
    var $thumb = $('<li class="thumb" ></li>').appendTo($thumbs);
    var $thumbImage = $('<img src="' + $img.prop('src') + '" />').appendTo($thumb);
  });
  $postGallery.children().remove();
  $Gallery.appendTo($postGallery);
  return $Gallery;
}

var $sliders = $('.Gallery');
var sliders = [];
if ($sliders.length) {
  $sliders.each(function (idx, el) {
    sliders.push(new Gallery(el));
  });
}

var $postSliders = $('.PostGallery');
if ($postSliders.length) {
  $postSliders.each(function (idx, el) {
    $slider = createGallery(el);
    sliders.push(new Gallery($slider));
  });
}

$('.product-description').each(function() {
	var $pd = $(this);
    if($pd.height() > 400) {
      $pd.addClass('less');
      $pd.find('.more').on('click', function() {
      	$pd.removeClass('less').addClass('more');
      });
      $pd.find('.less').on('click', function() {
      	$pd.removeClass('more').addClass('less');
      });
    }
});